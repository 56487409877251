const debounce = <T extends (...args: any[]) => void>(func: T, delay: number) => {
  let timer: NodeJS.Timeout | null
  return (...args: Parameters<T>) => {
    clearTimeout(timer as NodeJS.Timeout)
    timer = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

export default debounce
