// ** React Imports
import React, { SVGProps } from 'react'

const ResearchReportsIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' {...props}>
      <g clip-path='url(#clip0_1248_463)'>
        <path
          d='M5.44092 1.95141C5.70417 1.95141 5.9294 1.97774 6.1166 2.03039C6.3038 2.08304 6.47345 2.15324 6.62555 2.24099C6.77765 2.32874 6.9122 2.41942 7.0292 2.51302C7.1462 2.60662 7.2632 2.69729 7.3802 2.78504C7.4972 2.87279 7.6259 2.94007 7.7663 2.98687C7.9067 3.03367 8.06757 3.06292 8.24892 3.07462H16.9537C17.1117 3.07462 17.2579 3.10387 17.3925 3.16237C17.527 3.22087 17.644 3.29984 17.7435 3.39929C17.8429 3.49874 17.9248 3.61867 17.9892 3.75907C18.0535 3.89947 18.0828 4.04572 18.0769 4.19782V7.56742H16.9537V4.19782H8.24892C8.06757 4.19782 7.90962 4.22414 7.77507 4.27679C7.64052 4.32944 7.51182 4.39964 7.38897 4.48739C7.26612 4.57514 7.1462 4.66582 7.0292 4.75942C6.9122 4.85302 6.78057 4.94369 6.63432 5.03144C6.48807 5.11919 6.31842 5.18647 6.12537 5.23327C5.93232 5.28007 5.70417 5.30932 5.44092 5.32102H1.22891V15.4298H2.35212V16.553H0.105713V3.07462C0.105713 2.91667 0.134963 2.77042 0.193463 2.63587C0.251963 2.50132 0.330938 2.38432 0.430388 2.28487C0.529839 2.18542 0.649764 2.10352 0.790164 2.03916C0.930564 1.97481 1.07681 1.94556 1.22891 1.95141H5.44092ZM5.44092 4.19782C5.58132 4.19782 5.70417 4.18612 5.80947 4.16272C5.91477 4.13932 6.0113 4.10129 6.09905 4.04864C6.1868 3.99599 6.27162 3.93749 6.35352 3.87314C6.43542 3.80879 6.52902 3.72982 6.63432 3.63622C6.53487 3.54847 6.4442 3.47242 6.3623 3.40807C6.2804 3.34372 6.19265 3.28522 6.09905 3.23257C6.00545 3.17992 5.90892 3.14189 5.80947 3.11849C5.71002 3.09509 5.58717 3.08047 5.44092 3.07462H1.22891V4.19782H5.44092ZM18.0769 8.69062V18.7994H3.47532V8.69062H18.0769ZM16.9537 9.81383H4.59852V17.6762H16.9537V9.81383ZM15.8305 12.0602H12.4609V10.937H15.8305V12.0602ZM14.7073 14.3066H12.4609V13.1834H14.7073V14.3066ZM14.7073 16.553H12.4609V15.4298H14.7073V16.553ZM11.3377 16.553H5.72172V10.937H11.3377V16.553ZM10.2145 12.0602H6.84492V15.4298H10.2145V12.0602Z'
          fill={props.color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id='clip0_1248_463'>
          <rect
            width='17.9712'
            height='17.9712'
            fill={props.color ?? 'currentColor'}
            transform='translate(0.105713 0.828125)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ResearchReportsIcon
