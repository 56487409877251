// ** React Imports
import React from 'react'
import { SidebarPageWrapperProps } from './SidebarPageWrapperProps'

function SidebarPageWrapper({ renderSidebar, children, ...rest }: SidebarPageWrapperProps) {
  return (
    <div className='flex h-full'>
      {renderSidebar}
      <div className={`w-full ${rest.className}`}>{children}</div>
    </div>
  )
}

export default SidebarPageWrapper
