import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { clearAccessToken, setAccessToken, setUser, clearUser } from '@redux/auth/authSlice'
import { useAppSelector } from '@redux/store'
import { loginUser, fetchUserInfo } from '@api/auth'
import { useNavigate } from 'react-router-dom'
import { API } from '@api/index'

interface AuthProviderProps {
  children: React.ReactNode
}

interface AuthContextType {
  accessToken: string | null
  isLoggedIn: boolean
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  validateToken: () => Promise<void>
  loginLoading: boolean // Added this line
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const accessToken = useAppSelector(state => state.auth.accessToken)
  const [isTokenValidated, setIsTokenValidated] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false) // Added this line

  const validateToken = async () => {
    const token = sessionStorage.getItem('accessToken')
    if (token) {
      try {
        API.defaults.headers.common['Authorization'] = `Bearer ${token}`
        const userInfo = await fetchUserInfo()
        dispatch(setAccessToken(token))
        dispatch(setUser(userInfo))
      } catch (error) {
        sessionStorage.removeItem('accessToken')
        dispatch(clearAccessToken())
        dispatch(clearUser())
        navigate('/login')
      }
    }
    setIsTokenValidated(true) // Mark token validation as completed
  }

  useEffect(() => {
    if (!isTokenValidated) {
      validateToken()
    }
  }, [dispatch, navigate, isTokenValidated])

  const login = async (email: string, password: string) => {
    setLoginLoading(true) // Start loading
    try {
      const response = await loginUser({ email, password })
      const { access_token } = response
      sessionStorage.setItem('accessToken', access_token)
      API.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
      const userInfo = await fetchUserInfo()
      dispatch(setAccessToken(access_token))
      dispatch(setUser(userInfo))
      navigate('/dashboard')
    } catch (error) {
      console.error('Login failed:', error)
      throw error
    } finally {
      setLoginLoading(false) // End loading
    }
  }

  const logout = () => {
    sessionStorage.removeItem('accessToken')
    dispatch(clearAccessToken())
    dispatch(clearUser())
    navigate('/login')
  }

  const isLoggedIn = !!accessToken

  const authContextValue: AuthContextType = {
    accessToken,
    isLoggedIn,
    login,
    logout,
    validateToken,
    loginLoading // Added this line
  }

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
}
