// ** Sections Imports
import UseCases from './components/UseCases'
import YourData from './components/YourData'
import OurSponsors from './components/OurSponsors'
import GetMoreDone from './components/GetMoreDone'
import WorkTogether from './components/WorkTogether'
import CustomizedNeeds from './components/CustomizedNeeds'
import OptimizingAnalysts from './components/OptimizingAnalysts'

const Home = () => {
  return (
    <div>
      <GetMoreDone id='hero' />
      <OptimizingAnalysts id='optimizing' />
      <UseCases id='use_cases' />
      <WorkTogether id='work_together' />
      <CustomizedNeeds id='needs' />
      <YourData id='your_data' />
      <OurSponsors id='sponsors' />
    </div>
  )
}

export default Home
