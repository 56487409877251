import * as React from 'react'
import { createStore, Plugin, PluginFunctions } from '@react-pdf-viewer/core'

interface StoreProps {
  getPagesContainer?(): HTMLElement
}

interface ReadingIndicatorPlugin extends Plugin {
  ReadingIndicator: () => React.ReactElement
}

// Create the store outside of the plugin function
const store = createStore<StoreProps>({})

const ReadingIndicator = ({ store }: any) => {
  const [percentages, setPercentages] = React.useState(0)

  const handleScroll = React.useCallback((e: Event) => {
    const target = e.target
    if (target instanceof HTMLDivElement) {
      const p = Math.floor((100 * target.scrollTop) / (target.scrollHeight - target.clientHeight))
      setPercentages(Math.min(100, p))
    }
  }, [])

  React.useLayoutEffect(() => {
    const getPagesContainer = store.get('getPagesContainer')
    if (!getPagesContainer) {
      return
    }

    const pagesEle = getPagesContainer()
    if (pagesEle) {
      pagesEle.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (pagesEle) {
        pagesEle.removeEventListener('scroll', handleScroll)
      }
    }
  }, [store, handleScroll])

  return (
    <div
      style={{
        height: '4px'
      }}
    >
      <div
        style={{
          backgroundColor: 'rgb(53, 126, 221)',
          height: '100%',
          width: `${percentages}%`
        }}
      />
    </div>
  )
}

const readingIndicatorPlugin = (): ReadingIndicatorPlugin => {
  const ReadingIndicatorDecorator = () => <ReadingIndicator store={store} />

  return {
    install: (pluginFunctions: PluginFunctions) => {
      store.update('getPagesContainer', pluginFunctions.getPagesContainer)
    },
    ReadingIndicator: ReadingIndicatorDecorator
  }
}

export default readingIndicatorPlugin

// import * as React from 'react'
// import { createStore, Plugin, PluginFunctions } from '@react-pdf-viewer/core'

// interface StoreProps {
//   getPagesContainer?(): HTMLElement
// }

// interface ReadingIndicatorPlugin extends Plugin {
//   ReadingIndicator: () => React.ReactElement
// }

// // Create the store outside of the plugin function
// const store = createStore<StoreProps>({})

// const ReadingIndicator = ({ store }: any) => {
//   const [percentages, setPercentages] = React.useState(0)

//   const handleScroll = React.useCallback((e: Event) => {
//     const target = e.target
//     if (target instanceof HTMLDivElement) {
//       const p = Math.floor((100 * target.scrollTop) / (target.scrollHeight - target.clientHeight))
//       setPercentages(Math.min(100, p))
//     }
//   }, [])

//   React.useLayoutEffect(() => {
//     const getPagesContainer = store.get('getPagesContainer')
//     if (!getPagesContainer) {
//       return
//     }

//     const pagesEle = getPagesContainer()
//     if (pagesEle) {
//       pagesEle.addEventListener('scroll', handleScroll)
//     }

//     return () => {
//       if (pagesEle) {
//         pagesEle.removeEventListener('scroll', handleScroll)
//       }
//     }
//   }, [store, handleScroll])

//   return (
//     <div
//       style={{
//         height: '4px'
//       }}
//     >
//       <div
//         style={{
//           backgroundColor: 'rgb(53, 126, 221)',
//           height: '100%',
//           width: `${percentages}%`
//         }}
//       />
//     </div>
//   )
// }

// const readingIndicatorPlugin = (): ReadingIndicatorPlugin => {
//   const ReadingIndicatorDecorator = () => <ReadingIndicator store={store} />

//   return {
//     install: (pluginFunctions: PluginFunctions) => {
//       store.update('getPagesContainer', pluginFunctions.getPagesContainer)
//     },
//     ReadingIndicator: ReadingIndicatorDecorator
//   }
// }

// export default readingIndicatorPlugin
