// ** Types Imports
import { themeColor } from '../../types/theme/themeColor'

/**
 * A function that returns the tailwind
 * class name for appropriate background color
 * @param themeColor Color of the background
 */
export const getBackgroundColorClassName = (themeColor: themeColor) => {
  const backgroundColor =
    themeColor === 'primary-blue'
      ? 'bg-primary-blue'
      : themeColor === 'secondary-blue'
        ? 'bg-secondary-blue'
        : themeColor === 'accent-orange'
          ? 'bg-accent-orange'
          : themeColor === 'background-cream'
            ? 'bg-background-cream'
            : themeColor === 'light-blue'
              ? 'bg-light-blue'
              : themeColor === 'chizl-black' && 'bg-chizl-black'

  return backgroundColor
}
