/**
 * A function executed when the App component loads
 * to check what the value of the theme should be
 */
function checkInitialThemeOnLoad() {
  /**
   * For now the initial value will always be dark,
   * since there's no light mode design implemented yet
   */
  document.documentElement.classList.add('dark')
}

export { checkInitialThemeOnLoad }
