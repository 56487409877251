// ** Icons Imports
import { FaLinkedinIn } from 'react-icons/fa6'

function FooterEnd() {
  return (
    <div className='p-[30px] flex items-center justify-between'>
      <span>©2024 Chizl Technology Solutions FZ-LLC</span>

      <FaLinkedinIn />
    </div>
  )
}

export default FooterEnd
