// ** React Imports
import { useEffect, useState } from 'react'

// ** React Router Imports
import { Link, useLocation } from 'react-router-dom'

// ** Props Imports
import { dashboardNavButtonProps } from './dashboardNavButtonProps'

function DashboardNavButton({
  color = 'secondary-blue',
  href,
  label,
  locked = false,
  endIcon,
  ...rest
}: dashboardNavButtonProps) {
  // ** Hooks
  const location = useLocation()

  // ** States
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    console.log(href)
    // On location change -> check if the button href matches the url
    if (location.pathname.includes(String(href))) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }, [location, href])

  return (
    <div className='relative'>
      <Link to={locked ? '#' : href}>
        <button
          className={`${locked ? 'opacity-50' : ''} ${isSelected ? 'bg-secondary-blue' : 'bg-light-black'} mx-auto rounded-[12px] h-[39px] flex-center font-semibold leading-4 text-center px-[10px] ${rest.className}`}
          style={rest.style}
        >
          {endIcon}
          {label}
        </button>
      </Link>
    </div>
  )
}

// ** Display Name
DashboardNavButton.displayName = 'DashboardNavButton'

export default DashboardNavButton
