import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '@api/auth'

interface AuthState {
  accessToken: string | null
  user: User | null
}

// Retrieve the token from sessionStorage
const storedAccessToken = sessionStorage.getItem('accessToken')

const initialState: AuthState = {
  accessToken: storedAccessToken || null,
  user: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload
    },
    clearAccessToken(state) {
      state.accessToken = null
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload
    },
    clearUser(state) {
      state.user = null
    }
  }
})

export const { setAccessToken, clearAccessToken, setUser, clearUser } = authSlice.actions
export default authSlice.reducer
