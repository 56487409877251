// ** React Imports
import React from 'react'
import { Link } from 'react-router-dom'

// ** Assets Imports
import illustration from './assets/illustration.png'

// ** Icon Imports
import { FaExternalLinkAlt } from 'react-icons/fa'

// ** Component Imports
import SectionContainer from '@coreComponents/SectionContainer'
import ChizlIcon from '@coreComponents/ChizlIcon'

function YourData({ id = '' }) {
  return (
    <SectionContainer id={id} className='flex lg:flex-row flex-col items-center py-[50px] gap-20'>
      {/* Left Section */}
      <div className='flex-1 flex-center'>
        <img src={illustration} alt='get-more-done' className='h-[300px] object-contain' />
      </div>

      {/* Right Section */}
      <div className='relative flex-1 flex items-center justify-center flex-col lg:block text-center lg:text-left'>
        <h1 className='text-5xl font-bold text-accent-orange'>100% Your Data</h1>
        <div className='text-xl mb-[50px] mt-[30px]'>
          Chizl's platform is confidential and secure. Although it is powered by Gemini, all information, documents, and
          conversations are shared with a private endpoint and the information shared on the platform will never be used
          to train Gemini's models.
        </div>

        <div className='underline flex items-center gap-1 text-accent-orange font-semibold'>
          <Link to='#' className='text-xl'>
            Read more
          </Link>
          <FaExternalLinkAlt />
        </div>

        <ChizlIcon icon='chizl-outline-orange' className='blur-sm absolute top-[-10px] right-[-100px] w-[300px]' />
      </div>
    </SectionContainer>
  )
}

export default YourData
