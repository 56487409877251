import React from 'react'

interface ModalProps {
  onClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ onClose, children }) => {
  return (
    <div className='z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='bg-light-black rounded w-full max-w-[377px]'>
        <button className='absolute top-0 right-0 p-2' onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  )
}

export default Modal
