// ** Props Imports
import { dashboardNavigationHeaderProps } from './dashboardNavigationHeaderProps'

// ** Config Imports
import { dashboardConfig } from '../../dashboardConfig'

// ** Component Imports
import DashboardNavButton from '@coreComponents/DashboardNavButton'
// import { BsStars } from 'react-icons/bs'

function DashboardNavigationHeader({ ...rest }: dashboardNavigationHeaderProps) {
  return (
    <div className={`h-[45px] p-[4px] flex w-full ${rest.className}`}>
      <div className='flex items-center gap-[20px] justify-between ml-auto'>
        {/* <DashboardNavButton endIcon={<BsStars />} label='Chizl' href={`/dashboard/general`} /> */}
        <DashboardNavButton
          label={dashboardConfig.pages.publicFilings.title}
          href={`/dashboard/${dashboardConfig.pages.publicFilings.pathname}`}
        />
        <DashboardNavButton
          label={dashboardConfig.pages.multiReports.title}
          href={`/dashboard/${dashboardConfig.pages.multiReports.pathname}`}
        />
        {/* <DashboardNavButton
          locked
          label={dashboardConfig.pages.transcriptions.title}
          href={`/dashboard/${dashboardConfig.pages.transcriptions.pathname}`}
        /> */}
      </div>
    </div>
  )
}

export default DashboardNavigationHeader
