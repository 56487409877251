// ** React Imports
import React, { useEffect } from 'react'

// ** Redux Imports
import { setOffering } from '@redux/chatBot/chatBotSlice'
import { useAppDispatch } from '@redux/store'

// ** Config Imports
import { useParams } from 'react-router-dom'
import GulfFilings from './GulfFilings'
import USFilings from './USFilings'

function PublicFilings() {
  // ** Hooks
  const dispatch = useAppDispatch()
  const { region } = useParams()

  // ** States

  useEffect(() => {
    dispatch(setOffering(1))
  }, [dispatch])

  if (region === 'us') {
    return <USFilings />
  } else {
    return <GulfFilings />
  }
}

export default PublicFilings
