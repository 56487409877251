import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Company,
  GCCFilingResponse,
  publicFilingDocumentInterface,
  PublicFilingsDataInterface
} from '@api/public_filings'

export interface PublicFilingsState {
  loading: boolean
  company?: Company
  publicFilings?: PublicFilingsDataInterface
  selectedPublicFiling?: publicFilingDocumentInterface
  exchanges: any[]
  selectedExchange?: any
  gcc_filings: any
  selectedGccFilingURL?: string
  selectedIndustries: string[]
}

const initialState: PublicFilingsState = {
  loading: false,
  company: undefined,
  publicFilings: undefined,
  selectedPublicFiling: undefined,
  exchanges: [],
  selectedExchange: undefined,
  gcc_filings: {},
  selectedGccFilingURL: undefined,
  selectedIndustries: []
}

export const publicFilingsSlice = createSlice({
  name: 'publicFilings',
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<Company>) => {
      state.company = action.payload
    },
    clearCompany: state => {
      state.company = undefined
    },
    setPublicFilings: (state, action: PayloadAction<PublicFilingsDataInterface>) => {
      state.publicFilings = action.payload
    },
    setSelectedPublicFiling: (state, action: PayloadAction<publicFilingDocumentInterface>) => {
      state.selectedPublicFiling = action.payload
    },
    clearSelectedPublicFIling: state => {
      state.selectedPublicFiling = undefined
    },
    clearPublicFilings: state => {
      state.publicFilings = undefined
    },
    setSelectedGccFilingURL: (state, action: PayloadAction<string>) => {
      state.selectedGccFilingURL = action.payload
    },
    clearSelectedGccFilingURL: state => {
      state.selectedGccFilingURL = undefined
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    clearState: state => {
      state.loading = initialState.loading
      state.company = initialState.company
      state.publicFilings = initialState.publicFilings
      state.selectedPublicFiling = initialState.selectedPublicFiling
      state.exchanges = initialState.exchanges
      state.selectedExchange = initialState.selectedExchange
      state.gcc_filings = initialState.gcc_filings
      state.selectedIndustries = initialState.selectedIndustries
    },
    setExchanges: (state, action: PayloadAction<any[]>) => {
      state.exchanges = action.payload
    },
    clearExchanges: state => {
      state.exchanges = []
      state.selectedExchange = undefined
    },
    setSelectedExchange: (state, action: PayloadAction<any>) => {
      state.selectedExchange = action.payload
    },
    setGCCFilings: (state, action: PayloadAction<any>) => {
      state.gcc_filings = action.payload
    },
    clearGCCFilings: state => {
      state.gcc_filings = {}
    },
    setSelectedIndustries: (state, action: PayloadAction<string[]>) => {
      state.selectedIndustries = action.payload
    }
  }
})

export const {
  setLoading,
  setCompany,
  clearCompany,
  setPublicFilings,
  clearPublicFilings,
  setSelectedPublicFiling,
  clearSelectedPublicFIling,
  clearState,
  setExchanges,
  clearExchanges,
  setSelectedExchange,
  setGCCFilings,
  clearGCCFilings,
  setSelectedGccFilingURL,
  clearSelectedGccFilingURL,
  setSelectedIndustries // New action to set selected industries
} = publicFilingsSlice.actions

export default publicFilingsSlice.reducer
