// ** React Imports
import React from 'react'

// ** Icons Imports
import { AiOutlineFieldTime } from 'react-icons/ai'

function DashboardComingSoon() {
  return (
    <div className='h-full flex-center gap-[10px] bg-light-black'>
      <span className='text-6xl text-background-cream font-semibold'>Coming Soon</span>
      <AiOutlineFieldTime size={60} color='var(--background-cream)' className='-mb-[14px]' />
    </div>
  )
}

export default DashboardComingSoon
