import React from 'react'

function Beta({ ...rest }) {
  return (
    <div {...rest} className={`text-accent-orange text-sm italic font-semibold ${rest.className}`}>
      BETA
    </div>
  )
}

export default Beta
