// ** React Imports
import React from 'react'
import ReactDOM from 'react-dom/client'

// ** Styles Imports
import './index.css'

// ** Component Imports
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
