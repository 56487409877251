// ** React Imports
import React, { useState } from 'react'

// ** Component Imports
import Box from '@coreComponents/Box'
import Input from '@coreComponents/Input'
import ClickAwayListener from '@coreComponents/ClickAwayListener'

// ** Props/Interface Imports
import { autocompleteProps, optionInterface } from './autocompleteProps'

function Autocomplete({
  options = [],
  inputProps = {},
  loading = false,
  onInputChange = () => {},
  onChange = () => {},
  containerStyle,
  ...rest
}: autocompleteProps) {
  // ** States
  const [value, setValue] = useState<string>('')
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const suggestions = options.filter(option => option.label.toLowerCase().includes(value.toLowerCase()))

  // ** Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    onInputChange(e)
  }

  const handleClose = () => {
    setShowSuggestions(false)
  }

  const handleSuggestionClick = (option: optionInterface) => {
    setValue(option.label)
    setShowSuggestions(false)
    onChange(option)
  }

  return (
    <ClickAwayListener onClickAway={handleClose} style={containerStyle}>
      <div className='relative'>
        <Input
          {...inputProps}
          className={`relative`}
          value={value}
          onChange={handleChange}
          boxClassName={`${showSuggestions && suggestions.length !== 0 && 'rounded-bl-none rounded-br-none'} ${inputProps.boxClassName}`}
          onFocus={() => setShowSuggestions(true)}
          boxStyle={{ width: '100%', borderRadius: '5px' }}
        />
        {showSuggestions && suggestions.length > 0 && (
          <Box
            color='background-cream'
            className='absolute rounded-tr-none rounded-tl-none w-full flex flex-col h-fit border-none'
            style={{ padding: 0 }}
          >
            {suggestions.map(option => (
              <button
                key={option.value}
                onClick={() => handleSuggestionClick(option)}
                className='w-full text-left p-[8px] bg-chizl-black last:rounded-bl-[10px] last:rounded-br-[10px] last:border-b-0 border-b-[1.5px] border-light-black'
              >
                {option.label}
              </button>
            ))}
          </Box>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default Autocomplete
