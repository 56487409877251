// ** React Imports
import React from 'react'

// ** Props Imports
import { ResearchReportFileProps } from './ResearchReportFileProps'

// ** Icons Imports
import PDFIcon from '@icons/PDFIcon'

// ! Delete this later
import { formatDateTime } from 'core/helpers/datetime/formatDateTime'

function ResearchReportFile({
  name,
  dateUploaded,
  extension,
  isSelected = false,
  isPrivate = false,
  imgUrl,
  ...rest
}: ResearchReportFileProps) {
  return (
    <button
      {...rest}
      className={`relative flex flex-col mt-[20px] items-center justify-between rounded-[12px] ${isSelected ? 'border-[1px] border-accent-orange shadow-accent-orange shadow-md' : 'border-[1px] border-chizl-black'} p-[8px] w-[225px] h-[197px] hover:shadow-sm hover:shadow-primary-blue transition-shadow ${rest.className}`}
    >
      {isPrivate && (
        <div className='absolute top-[-25px] left-0 bg-accent-orange bg-opacity-30 rounded-md px-[10px] text-sm text-accent-orange font-semibold'>
          {isPrivate && 'Private'}
        </div>
      )}
      {/* File Header */}
      <div className='flex items-center w-full gap-[7px] mb-[7px]'>
        <div>
          <PDFIcon width={20} height={20} color={isSelected ? 'var(--accent-orange)' : 'var(--primary-blue)'} />
        </div>
        <span className='text-ellipsis text-truncate text-sm'>{name}</span>
      </div>

      <img
        src={`https://chizl-public-docs.s3.amazonaws.com/${imgUrl}`}
        className='w-full h-[137px] border-none outline-none object-cover'
      />

      {dateUploaded && (
        <span className='text-[10px] text-background-cream'>Uploaded {formatDateTime(dateUploaded)}</span>
      )}
    </button>
  )
}

export default ResearchReportFile
