import React from 'react'

// ** Props
export interface sectionContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  /**
   * The class name of the outer container of the component
   */
  outerContainerClassName?: string
}

function SectionContainer({ children, outerContainerClassName, ...rest }: sectionContainerProps) {
  return (
    <div {...rest} className={`flex-center ${outerContainerClassName}`}>
      <div className={`section-container p-[20px] ${rest.className}`}>{children}</div>
    </div>
  )
}

export default SectionContainer
