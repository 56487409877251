// ** React Imports
import React, { SVGProps } from 'react'

const FileIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width='12' height='14' viewBox='0 0 12 14' fill='none'>
      <path
        d='M11.2686 4.72398C11.237 4.65162 11.1928 4.58548 11.138 4.52865L7.13796 0.528646C7.08113 0.473834 7.01498 0.429587 6.94263 0.39798C6.92263 0.388646 6.90129 0.383313 6.87996 0.37598C6.82418 0.356997 6.76611 0.345562 6.70729 0.34198C6.69329 0.340646 6.68063 0.333313 6.66663 0.333313H1.99996C1.26463 0.333313 0.666626 0.931313 0.666626 1.66665V12.3333C0.666626 13.0686 1.26463 13.6666 1.99996 13.6666H9.99996C10.7353 13.6666 11.3333 13.0686 11.3333 12.3333V4.99998C11.3333 4.98598 11.326 4.97331 11.3246 4.95865C11.3214 4.89979 11.3099 4.84168 11.2906 4.78598C11.284 4.76465 11.278 4.74398 11.2686 4.72398ZM9.05729 4.33331H7.33329V2.60931L9.05729 4.33331ZM1.99996 12.3333V1.66665H5.99996V4.99998C5.99996 5.17679 6.0702 5.34636 6.19522 5.47138C6.32025 5.59641 6.48982 5.66665 6.66663 5.66665H9.99996L10.0013 12.3333H1.99996Z'
        fill={props.color ?? 'currentColor'}
      />
      <path
        d='M3.33337 6.99998H8.66671V8.33331H3.33337V6.99998ZM3.33337 9.66665H8.66671V11H3.33337V9.66665ZM3.33337 4.33331H4.66671V5.66665H3.33337V4.33331Z'
        fill={props.color ?? 'currentColor'}
      />
    </svg>
  )
}

export default FileIcon
