// ** React Imports
import { Link } from 'react-router-dom'

// ** Icon Imports
import { FaExternalLinkAlt } from 'react-icons/fa'

// ** Props Imports
import { UseCaseBoxProps } from './UseCaseBoxProps'

function UseCaseBox({ title, description, icon, iconBgColor, ...rest }: UseCaseBoxProps) {
  return (
    <div
      className='group h-[190px] w-[250px] rounded-[15px] border-transparent border-2 hover:border-accent-orange transition-all hover:h-[190px]'
      {...rest}
    >
      <div className='bg-chizl-black rounded-[13px] p-[16px] h-[167px]'>
        <div className='flex items-center justify-between mb-2'>
          <h2 className='font-bold'>{title}</h2>

          <div className='rounded-full h-[34px] w-[34px] flex-center' style={{ backgroundColor: iconBgColor }}>
            {icon}
          </div>
        </div>

        <p className='leading-5 text-[14px]'>{description}</p>
      </div>

      {/* Read More */}
      <div className='flex mt-[-20px] opacity-0 items-center justify-end px-[10px] underline text-accent-orange flex-center gap-[10px] group-hover:mt-0 group-hover:opacity-100 transition-all'>
        <Link to='#' className='text-sm'>
          Read more
        </Link>
        <FaExternalLinkAlt size={10} />
      </div>
    </div>
  )
}

export default UseCaseBox
