// ** React
import React, { MutableRefObject, useState } from 'react'

// ** Props
import { UseCasesMobileProps } from './UseCasesMobileProps'

// ** Third-party Components
import { useKeenSlider } from 'keen-slider/react'
import type { KeenSliderPlugin, KeenSliderInstance } from 'keen-slider/react'

import { useCases } from '../../data'
import UseCaseBox from '../UseCaseBox'

const ThumbnailPlugin = (mainRef: MutableRefObject<KeenSliderInstance | null>): KeenSliderPlugin => {
  return slider => {
    const removeActive = () => {
      slider.slides.forEach(slide => {
        slide.classList.remove('active')
        slide.style.borderColor = 'var(--chizl-black)'
      })
    }

    const addActive = (idx: number) => {
      slider.slides[idx].classList.add('active')
      slider.slides[idx].style.borderWidth = '3px'
      slider.slides[idx].style.borderColor = 'var(--accent-orange)'
      slider.slides[idx].style.borderRadius = '20px'
    }

    const addClickEvents = () => {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on('created', () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on('animationStarted', main => {
        removeActive()
        const next = main.animator.targetIdx || 0

        addActive(main.track.absToRel(next))
        slider.moveToIdx(next)
      })
    })
  }
}

function UseCasesMobile({ ...rest }: UseCasesMobileProps) {
  // ** States
  const [loaded, setLoaded] = useState<boolean>(false)
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  // ** Hooks
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    }
  })
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      slides: {
        perView: 4,
        spacing: 16
      },
      breakpoints: {
        [`(max-width: 950px)`]: {
          slides: {
            perView: 2,
            spacing: 8
          }
        },
        [`(max-width: 610px)`]: {
          slides: {
            perView: 1,
            spacing: 8
          }
        }
      }
    },
    [ThumbnailPlugin(instanceRef)]
  )

  return (
    <div {...rest} className={`${rest.className} pt-[50px]`}>
      <div ref={sliderRef} className='keen-slider'>
        {useCases.map(({ title, thumbnail }) => (
          <div className='flex keen-slider__slide'>
            <img src={thumbnail} alt={title} className='sm:w-[64%] w-full mx-auto rounded-[26px]' />
          </div>
        ))}
      </div>

      <div ref={thumbnailRef} className='mbs-4 keen-slider thumbnail pt-[20px]'>
        {useCases.map(({ title, icon, iconBgColor, description }) => (
          <UseCaseBox
            title={title}
            icon={icon}
            iconBgColor={iconBgColor}
            description={description}
            className='keen-slider__slide cursor-pointer'
          />
        ))}
      </div>

      {loaded && instanceRef.current && (
        <div className=' flex-center my-md gap-[4px]'>
          {Array(instanceRef.current.track.details.slides.length)
            .fill(0)
            .map((_, idx) => (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
                className={`h-[7.5px] w-[7.5px] rounded-full ${currentSlide === idx ? 'bg-accent-orange' : 'bg-white'}`}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default UseCasesMobile
