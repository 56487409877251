// ** React Imports
import React, { SVGProps } from 'react'

const UploadIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M5.79159 9.33331V3.5604L3.94992 5.40206L2.95825 4.37498L6.49992 0.833313L10.0416 4.37498L9.04992 5.40206L7.20825 3.5604V9.33331H5.79159ZM2.24992 12.1666C1.86034 12.1666 1.52695 12.028 1.24975 11.7509C0.972557 11.4737 0.833724 11.14 0.833252 10.75V8.62498H2.24992V10.75H10.7499V8.62498H12.1666V10.75C12.1666 11.1396 12.028 11.4732 11.7508 11.7509C11.4736 12.0285 11.14 12.1671 10.7499 12.1666H2.24992Z'
        fill={props.color ?? 'currentColor'}
      />
    </svg>
  )
}

export default UploadIcon
