// ** React Imports
import React, { SVGProps } from 'react'

const SearchIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.0667 12L6.86667 7.8C6.53333 8.06667 6.15 8.27778 5.71667 8.43333C5.28333 8.58889 4.82222 8.66667 4.33333 8.66667C3.12222 8.66667 2.09733 8.24711 1.25867 7.408C0.42 6.56889 0.000444444 5.544 0 4.33333C0 3.12222 0.419556 2.09733 1.25867 1.25867C2.09778 0.42 3.12267 0.000444444 4.33333 0C5.54444 0 6.56956 0.419556 7.40867 1.25867C8.24778 2.09778 8.66711 3.12267 8.66667 4.33333C8.66667 4.82222 8.58889 5.28333 8.43333 5.71667C8.27778 6.15 8.06667 6.53333 7.8 6.86667L12 11.0667L11.0667 12ZM4.33333 7.33333C5.16667 7.33333 5.87511 7.04178 6.45867 6.45867C7.04222 5.87556 7.33378 5.16711 7.33333 4.33333C7.33333 3.5 7.04178 2.79178 6.45867 2.20867C5.87556 1.62556 5.16711 1.33378 4.33333 1.33333C3.5 1.33333 2.79178 1.62511 2.20867 2.20867C1.62556 2.79222 1.33378 3.50044 1.33333 4.33333C1.33333 5.16667 1.62511 5.87511 2.20867 6.45867C2.79222 7.04222 3.50044 7.33378 4.33333 7.33333Z'
        fill={props.color ?? 'currentColor'}
      />
    </svg>
  )
}

export default SearchIcon
