// ** Types Imports
import { themeColor } from '../../types/theme/themeColor'

/**
 * A function that returns the tailwind
 * class name for appropriate border color
 * @param themeColor Color of the border
 */
export const getBorderColorClassName = (themeColor: themeColor) => {
  const borderColor =
    themeColor === 'primary-blue'
      ? 'border-primary-blue'
      : themeColor === 'secondary-blue'
        ? 'border-secondary-blue'
        : themeColor === 'accent-orange'
          ? 'border-accent-orange'
          : themeColor === 'background-cream'
            ? 'border-background-cream'
            : themeColor === 'light-blue'
              ? 'border-light-blue'
              : themeColor === 'chizl-black' && 'border-chizl-black'

  return borderColor
}
