import { AxiosError } from 'axios'
import { API } from '..' // Adjust import path as per your project structure

export interface User {
  email: string
  firstname: string
  id: number
  lastname: string
  username: string
}

interface AuthResponse {
  access_token: string
  token_type: string
}

// Function to register a new user
export const registerUser = async (userData: {
  firstname: string
  lastname: string
  email: string
  username: string
  password: string
}) => {
  try {
    const response = await API.post('/users/create_user', userData)
    return response.data // Assuming the API returns data upon successful registration
  } catch (error) {
    handleApiError(error)
    throw error
  }
}

// Function to login and obtain an access token
export const loginUser = async (userData: { email: string; password: string }): Promise<AuthResponse> => {
  try {
    const response = await API.post<AuthResponse>('/users/token', userData)
    return response.data
  } catch (error) {
    handleApiError(error as AxiosError)
    throw error
  }
}

// Function to fetch user information and validate the token
export const fetchUserInfo = async (): Promise<User> => {
  try {
    const response = await API.get<User>('/users/me')
    return response.data
  } catch (error) {
    handleApiError(error)
    throw error
  }
}

// Function to handle API errors (example implementation)
const handleApiError = (error: unknown) => {
  if (isAxiosError(error)) {
    // The request was made and the server responded with a status code
    console.error('API error status:', error.response?.status)
    console.error('API error data:', error.response?.data)
  } else if (error instanceof Error) {
    // Something happened in setting up the request that triggered an Error
    console.error('API error message:', error.message)
  } else {
    console.error('Unknown error:', error)
  }
}

// Type guard to check if error is AxiosError
const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).isAxiosError !== undefined
}
