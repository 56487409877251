// ** React Imports
import React from 'react'

// ** React Router
import { Link } from 'react-router-dom'

// ** Redux
import { useAppDispatch } from '@redux/store'

// ** Props Imports
import { publicFilingsTableRowProps } from './publicFilingsTableRowProps'
import { formatDateTime } from 'core/helpers/datetime/formatDateTime'
import { setSelectedPublicFiling } from '@redux/publicFilings/publicFilingsSlice'

function PublicFilingsTableRow({ row, ...rest }: publicFilingsTableRowProps) {
  // ** Hooks
  const dispatch = useAppDispatch()

  // ** ClassNames
  const tdClassNames = 'px-[10px] py-[7px] text-sm text-truncate'

  // TODO: Make this a reusable utility function
  /**
   * Convert the timestamp to a formatted date string
   * @param inputDate The date to be formatted
   * @returns The date in the correct date formate
   */
  function convertDateFormat(inputDate: string | undefined): string {
    if (!inputDate) return ''
    const date = new Date(inputDate)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Note: January is 0
    const year = date.getFullYear().toString()
    return `${day}/${month}/${year}`
  }

  const handlePublicFilingClick = () => {
    row && dispatch(setSelectedPublicFiling(row))
  }

  if (!row) return <></>
  return (
    <tr className='border-b-[1.5px] border-[#5B5B5B]'>
      <td className={`${tdClassNames} w-1/4 text-primary-blue`}>
        <Link
          to={`/dashboard/public_filings/us/${row.id}`}
          className='text-sm hover:underline'
          onClick={handlePublicFilingClick}
        >
          {row.type}
        </Link>
      </td>

      {row.title && (
        <td
          className={`${tdClassNames} flex justify-between items-center gap-[10px] border-x-[1.5px] border-[#5B5B5B]`}
        >
          <span className='text-sm'>{row.title}</span>
          {row.filing_period && (
            <span className='text-xs text-[#a6a6a6]'>ended {formatDateTime(row.filing_period)}</span>
          )}
        </td>
      )}
      <td className={`${tdClassNames} w-1/4 border-x-[1.5px] border-[#5B5B5B]`}>{convertDateFormat(row.date)}</td>
    </tr>
  )
}

export default PublicFilingsTableRow
