// ** React Imports
import React, { SVGProps } from 'react'

const DatabaseIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width='21' height='21' viewBox='0 0 21 21' fill='none' {...props}>
      <path
        d='M19.707 5.46616C19.707 7.74999 15.5424 9.5998 10.4063 9.5998C5.27029 9.5998 1.10565 7.74999 1.10565 5.46616M19.707 5.46616C19.707 3.18232 15.5424 1.33252 10.4063 1.33252C5.27029 1.33252 1.10565 3.18232 1.10565 5.46616M19.707 5.46616V10.6332M1.10565 5.46616V10.6332M19.707 10.6332C19.707 12.917 15.5424 14.7668 10.4063 14.7668C5.27029 14.7668 1.10565 12.917 1.10565 10.6332M19.707 10.6332V15.8003C19.707 18.0841 15.5424 19.9339 10.4063 19.9339C5.27029 19.9339 1.10565 18.0841 1.10565 15.8003V10.6332'
        stroke={props.color ?? 'currentColor'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default DatabaseIcon
