export const regions = [
  {
    name: 'USA',
    exchanges: ['nyse', 'nasdaq'],
    code: 'us',
    disabled: true
  },
  {
    name: 'Saudi Arabia',
    exchanges: ['tasi', 'nomu'],
    code: 'sa',
    disabled: false
  },
  {
    name: 'UAE',
    exchanges: ['dfm', 'adx'],
    code: 'ae',
    disabled: false
  },
  {
    name: 'Qatar',
    exchanges: ['qse'],
    code: 'qa',
    disabled: false
  },
  {
    name: 'Kuwait',
    exchanges: ['bk'],
    code: 'kw',
    disabled: false
  },
  {
    name: 'Bahrain',
    exchanges: ['bhb'],
    code: 'bh',
    disabled: false
  }
]

export const S3BaseURL = 'https://chizl-public-docs.s3.amazonaws.com'
