// ** Component Imports
import FooterEnd from './components/FooterEnd'
import FooterBody from './components/FooterBody'

function Footer() {
  return (
    <footer className='bg-chizl-black'>
      <FooterBody />
      <FooterEnd />
    </footer>
  )
}

export default Footer
