// ** React Imports
import React, { useEffect, useState } from 'react'

// ** React Router Dom Imports
import { useNavigate, useParams } from 'react-router-dom'

// ** Utils
import { arrayBufferToBase64 } from '@utils/arrayBufferToBase64'

// ** Redux Imports
import { useAppDispatch, useAppSelector } from '@redux/store'
import { addChatBotSelectedDocument, clearSelectedDocuments } from '@redux/chatBot/chatBotSlice'
import { clearSelectedPublicFIling, setSelectedPublicFiling } from '@redux/publicFilings/publicFilingsSlice'

// ** API/Interface Imports
import { _getPublicFilingById, _getPublicFilingPDF, publicFilingDocumentInterface } from '@api/public_filings'

// ** Component Imports
import ViewPublicFilingSidebar from './ViewPublicFilingSidebar'
import SidebarPageWrapper from '@coreComponents/sidebar-components/SidebarPageWrapper'

// ** Get the version of pdfjs-dist
import { Icon } from '@react-pdf-viewer/core'
import pdfjsVersion from 'pdfjs-dist/package.json'

// ** PDF Viewer
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core'
import { ToolbarSlot, defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import readingIndicatorPlugin from '@components/pdf-viewer/readingIndicatorPlugin' // Import the reading indicator plugin
import { IoArrowBack } from 'react-icons/io5'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { RenderHighlightsProps, searchPlugin } from '@react-pdf-viewer/search'

function ViewPublicFiling() {
  // ** Hooks
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // ** Router Params
  type RouteParams = {
    documentId?: string
    regions: string
  }
  const routeParams = useParams<RouteParams>()

  // ** States
  const [pdfContent, setPdfContent] = useState<string | undefined>(undefined)
  const publicFilingsStore = useAppSelector(state => state.publicFilings)
  const selectedDocuments = useAppSelector(state => state.chatBot.selectedDocuments)

  // Document In View
  const selectedDocument: publicFilingDocumentInterface | undefined = publicFilingsStore.selectedPublicFiling

  // Fetch the PDF content from the document url
  const fetchPDF = (url: string) => {
    _getPublicFilingPDF(url, data => {
      // Convert ArrayBuffer to Base64
      const base64String = arrayBufferToBase64(data)
      setPdfContent(`data:application/pdf;base64,${base64String}`)
    })
  }

  useEffect(() => {
    // Fetch a filing by ID
    const fetchFilingData = () => {
      if (!routeParams.documentId) return
      if (selectedDocument) {
        fetchPDF(selectedDocument.document_url)
      } else if (!selectedDocument && routeParams.documentId) {
        _getPublicFilingById(routeParams.documentId, data => {
          dispatch(setSelectedPublicFiling(data))
          dispatch(
            addChatBotSelectedDocument({
              id: data?.id,
              name: `${data.title}` ?? ''
            })
          )
          fetchPDF(data.document_url)
        })
      }
    }

    // Set the selected document for the chatbot
    selectedDocument &&
      dispatch(
        addChatBotSelectedDocument({
          id: selectedDocument?.id,
          name: `${selectedDocument.type} - ${selectedDocument.title}` ?? ''
        })
      )

    fetchFilingData()

    return () => {
      // Clear the selected document on unmount
      dispatch(clearSelectedPublicFIling())
      dispatch(clearSelectedDocuments())
    }
  }, [dispatch, routeParams, selectedDocument])

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar: any) => (
      <>
        <Toolbar>
          {(slots: ToolbarSlot) => {
            const {
              CurrentPageInput,
              Download,
              EnterFullScreen,
              GoToNextPage,
              GoToPreviousPage,
              NumberOfPages,
              ShowSearchPopover,
              Zoom,
              ZoomIn,
              ZoomOut,
              ShowProperties
            } = slots

            return (
              <div className='flex items-center justify-between w-full pl-[11px]'>
                <div className='flex items-center gap-[5px]'>
                  <button className='mb-[-2px]' onClick={() => navigate(-1)}>
                    <IoArrowBack size={20} />
                  </button>
                  {selectedDocuments && <span className='font-semibold ml-[10px]'>{selectedDocuments[0]?.name}</span>}
                </div>

                <div className='flex items-center ml-auto'>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToPreviousPage />
                  </div>
                  <div style={{ padding: '0px 2px', display: 'flex', alignItems: 'center' }}>
                    <CurrentPageInput /> / <NumberOfPages />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToNextPage />
                  </div>
                </div>

                <div className='flex items-center ml-[30px]'>
                  <ZoomOut />
                  <Zoom />
                  <ZoomIn />
                </div>

                <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                  <EnterFullScreen />
                </div>
                <div style={{ padding: '0px 2px' }}>
                  <Download />
                </div>
              </div>
            )
          }}
        </Toolbar>
        <div
          style={{
            bottom: '-0.25rem',
            position: 'absolute',
            left: 0,
            width: '100%'
          }}
        >
          {readingIndicatorPlugin().ReadingIndicator()}
        </div>
      </>
    ),
    sidebarTabs: defaultTabs =>
      defaultTabs.concat({
        content: <div style={{ textAlign: 'center', width: '100%', color: 'white' }}>Notes are listed here</div>,
        icon: (
          <Icon size={16}>
            <path d='M23.5,17a1,1,0,0,1-1,1h-11l-4,4V18h-6a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z' />
            <path d='M5.5 12L18.5 12' />
            <path d='M5.5 7L18.5 7' />
          </Icon>
        ),
        title: 'Notes'
      })
  })

  const darkThemeStyle = {
    backgroundColor: '#2e2e2e',
    color: 'white',
    height: '100vh'
  }

  const renderHighlights = React.useCallback(
    (renderProps: RenderHighlightsProps) => (
      <>
        {renderProps.highlightAreas.map((area, index) => (
          <div
            key={`${area.pageIndex}-${index}`}
            style={{
              ...renderProps.getCssProperties(area),
              position: 'absolute',
              backgroundColor: 'rgba(243, 245, 39, 0.35)'
            }}
          >
            {/* The content of highlighted element */}
          </div>
        ))}
      </>
    ),
    []
  )
  const searchPluginInstance = searchPlugin({
    // onHighlightKeyword: (props: OnHighlightKeyword) => {
    //   // props.highlightEle.style.outline = '2px dashed blue'
    //   // props.highlightEle.style.backgroundColor = 'rgba(243, 245, 39, 0.48)'
    // },
    renderHighlights
  })
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const { jumpToPage } = pageNavigationPluginInstance

  const { highlight } = searchPluginInstance

  useEffect(() => {
    const handleHighlight = (event: CustomEvent) => {
      const { detail } = event
      if (highlight) {
        highlight(detail.text)
        jumpToPage(detail.page)
      }
    }

    window.addEventListener('highlight', handleHighlight as EventListener)

    return () => {
      window.removeEventListener('highlight', handleHighlight as EventListener)
    }
  }, [])

  const renderGccFiling = () => (
    <SidebarPageWrapper className='w-full overflow-y-auto' style={darkThemeStyle}>
      {publicFilingsStore.selectedGccFilingURL && (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion.version}/build/pdf.worker.min.js`}>
          <Viewer
            fileUrl={publicFilingsStore.selectedGccFilingURL}
            plugins={[
              defaultLayoutPluginInstance,
              readingIndicatorPlugin(),
              searchPluginInstance,
              pageNavigationPluginInstance
            ]}
            defaultScale={SpecialZoomLevel.PageWidth}
            theme='dark'
          />
        </Worker>
      )}
    </SidebarPageWrapper>
  )

  if (routeParams.regions !== 'us') {
    return renderGccFiling()
  }

  return (
    <SidebarPageWrapper className='w-full overflow-y-auto' style={darkThemeStyle}>
      {pdfContent && (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion.version}/build/pdf.worker.min.js`}>
          <Viewer
            fileUrl={pdfContent}
            plugins={[
              defaultLayoutPluginInstance,
              readingIndicatorPlugin(),
              searchPluginInstance,
              pageNavigationPluginInstance
            ]}
            defaultScale={SpecialZoomLevel.PageWidth}
            theme='dark'
          />
        </Worker>
      )}
    </SidebarPageWrapper>
  )
}

export default ViewPublicFiling
