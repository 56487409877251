// ** Props/Types Imports
import { themeColor } from '../../core/types/theme/themeColor'

// ** Page Components
import PublicFilings from './views/PublicFilings'
import Transcriptions from './views/Transcriptions'
import ResearchReports from './views/ResearchReports'

// ** Interfaces
// Dashboard Page Name
type PageName = 'publicFilings' | 'multiReports' | 'transcriptions'

// Page Configuration
interface PageConfig {
  pageColor: themeColor
  title: string
  pathname: string
  component: React.FC
}
// Pages mapping
type Pages = {
  [page in PageName]: PageConfig
}

// Dashboard configuration
interface DashboardConfigInterface {
  pages: Pages
  paddingClassName: string
}

export const dashboardConfig: DashboardConfigInterface = {
  pages: {
    publicFilings: {
      pageColor: 'primary-blue',
      title: 'Public Filings',
      pathname: 'public_filings',
      component: PublicFilings
    },
    multiReports: {
      pageColor: 'primary-blue',
      title: 'Multi Reports',
      pathname: 'multi_reports',
      component: ResearchReports
    },
    transcriptions: {
      pageColor: 'accent-orange',
      title: 'Transcriptions',
      pathname: 'transcriptions',
      component: Transcriptions
    }
  },
  paddingClassName: 'p-[25px]'
}
