// ** Assets Imports
import img1 from './assets/1.png'
import img2 from './assets/2.png'
import img3 from './assets/3.png'
import img4 from './assets/4.png'

// ** Component Imports
import SectionContainer from '@coreComponents/SectionContainer'

function OurSponsors({ id = '' }) {
  return (
    <SectionContainer id={id} className='flex items-center py-[50px] flex-col'>
      <h1 className='text-5xl text-center font-bold mb-[90px] text-light-blue'>
        Institutional Analysts Enrolled in Beta Program
      </h1>

      {/* Sponsor icons container */}
      <div className='flex items-center w-full md:flex-row flex-col gap-[100px] flex-wrap justify-center'>
        <img src={img1} alt='img1' className='w-[200px]' />
        <img src={img2} alt='img2' className='w-[200px] scale-150' />
        <img src={img3} alt='img3' className='w-[200px]' />
        <img src={img4} alt='img4' className='w-[200px]' />
      </div>
    </SectionContainer>
  )
}

export default OurSponsors
