import { useAuth } from 'core/auth'
import React from 'react'
import { Navigate } from 'react-router-dom'

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { accessToken } = useAuth() // Assuming you have a useAuth hook to check authentication
  const isAuthenticated = !!accessToken // Check if user is authenticated

  return isAuthenticated ? <>{children}</> : <Navigate to='/login' replace />
}

export default ProtectedRoute
