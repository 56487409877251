import ChizlIcon from '@coreComponents/ChizlIcon'
import { Link } from 'react-router-dom'

function FooterBody() {
  return (
    <div className='px-[30px] py-[100px] flex items-center justify-start gap-[100px]'>
      <ChizlIcon icon='chizl-outline+text' />

      {/* Links */}
      <div>
        <div>
          <h5 className='font-semibold mb-[10px]'>Overview</h5>
          <Link to='#' className='text-accent-orange'>
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  )
}

export default FooterBody
