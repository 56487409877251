// ** React Imports
import React, { SVGProps } from 'react'

const RightArrowIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M1 5.5H11' stroke={props.color} stroke-linecap='round' stroke-linejoin='round' />
      <path d='M6 0.5L11 5.5L6 10.5' stroke={props.color} stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  )
}

export default RightArrowIcon
