// ** Props Imports
import { boxProps } from './boxProps'

// ** Helpers Imports
import { getBorderColorClassName } from '../../helpers/tailwind/getBorderColorClassName'
import { getBackgroundColorClassName } from '../../helpers/tailwind/getBackgroundColorClassName'

function Box({ color = 'primary-blue', ...rest }: boxProps) {
  // -- Border Color --
  const borderColor = getBorderColorClassName(color)

  // -- Background Color --
  const backgroundColor = getBackgroundColorClassName(color)

  return (
    <div
      className={`${borderColor} ${backgroundColor} border-[2.5px] rounded-xl h-[38px] px-[10px] w-fit bg-opacity-40 flex items-center ${rest.className}`}
      style={rest.style}
    >
      {rest.children}
    </div>
  )
}

// ** Display Name
Box.displayName = 'Box'

export default Box
