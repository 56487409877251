// ** React Imports
import React, { useEffect, useRef } from 'react'

// ** Props Imports
import { ClickAwayListenerProps } from './clickAwayListenerProps'

const ClickAwayListener: React.FC<ClickAwayListenerProps> = ({ onClickAway, children, ...rest }) => {
  // ** Refs
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onClickAway()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClickAway])

  return (
    <div ref={containerRef} {...rest}>
      {children}
    </div>
  )
}

export default ClickAwayListener
