// ** React Imports
import React from 'react'

// ** Redux Imports
import { useAppSelector } from '@redux/store'

// ** Props Imports
import { PublicFilingsSidebarProps } from './publicFilingsSidebarProps'

// ** Component Imports
import Sidebar from '@coreComponents/sidebar-components/Sidebar'
import SidebarLabel from '@coreComponents/sidebar-components/SidebarLabel'
import SidebarGroup from '@coreComponents/sidebar-components/SidebarGroup'
import SidebarButton from '@coreComponents/sidebar-components/SidebarButton'

function PublicFilingsSidebar({ ...rest }: PublicFilingsSidebarProps) {
  // ** States
  const publicFilingsStore = useAppSelector(state => state.publicFilings)

  return (
    <Sidebar>
      {publicFilingsStore.publicFilings && (
        <SidebarGroup color='background-cream'>
          <SidebarLabel>Nasdaq: AMZN</SidebarLabel>
          <SidebarButton color='primary-blue' textColor='background-cream' className='bg-opacity-70' isSelected>
            Categorized
          </SidebarButton>
          <SidebarButton color='primary-blue' textColor='background-cream' className='bg-opacity-70'>
            Chronological
          </SidebarButton>
          <SidebarButton color='primary-blue' textColor='background-cream' className='bg-opacity-70'>
            Transcripts
          </SidebarButton>
        </SidebarGroup>
      )}
    </Sidebar>
  )
}

export default PublicFilingsSidebar
