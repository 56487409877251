// ** React Imports
import React from 'react'

// ** Core Component Imports
import Button from '@coreComponents/Button'
import ChizlIcon from '@coreComponents/ChizlIcon'

// ** Icons Imports
// import { BsStars } from 'react-icons/bs'

// ** Utils Imports
import { smoothScrollTo } from 'core/utils/smoothScrollTo'

function Header() {
  return (
    <header className='flex-center z-50 fixed top-0 p-[20px] w-full border-b-primary-blue border-b-2 bg-light-black'>
      <div className='section-container flex items-center justify-between'>
        <ChizlIcon icon='chizl+textCream' />

        {/* Links Container */}
        <div>
          <nav className='hidden md:flex items-center justify-center'>
            <ul className='flex items-center justify-between gap-[36px]'>
              <li className='font-semibold cursor-pointer' onClick={() => smoothScrollTo('optimizing')}>
                Product
              </li>
              <li className='font-semibold cursor-pointer' onClick={() => smoothScrollTo('use_cases')}>
                Use Cases
              </li>
              <li className='font-semibold cursor-pointer' onClick={() => smoothScrollTo('about-us')}>
                About Us
              </li>
            </ul>
          </nav>
        </div>

        <Button color='accent-orange' href='/login'>
          Log In
        </Button>
      </div>
    </header>
  )
}

export default Header
