// ** React Imports
import React from 'react'
import { Link } from 'react-router-dom'

// ** Icon Imports
import { FaExternalLinkAlt } from 'react-icons/fa'

// ** Component Imports
import SectionContainer from '@coreComponents/SectionContainer'
import ChizlIcon from '@coreComponents/ChizlIcon'

function OptimizingAnalysts({ id = '' }) {
  return (
    <SectionContainer id={id}>
      <div
        className='flex bg-light-black lg:flex-row flex-col items-center p-[20px] py-[50px] gap-10 rounded-[60px] border-2 border-primary-blue lg:p-xl'
        style={{ boxShadow: '0 0 30px 0px var(--primary-blue)' }}
      >
        {/* Left Section */}
        <div className='flex-[1] flex flex-col gap-10 items-center text-center lg:text-left lg:items-start lg:justify-between h-[270px]'>
          <h1 className='text-5xl font-bold'>
            Optimizing analysts workflow through{' '}
            <span className='text-5xl font-bold text-primary-blue'>artificial intelligence</span>
          </h1>

          <div className='underline flex items-center gap-1 text-accent-orange font-semibold'>
            <Link to='#' className='text-xl'>
              Get Started
            </Link>
            <FaExternalLinkAlt />
          </div>

          <ChizlIcon sizes='20px' icon='chizl-outline+text' className='w-[100px]' />
        </div>

        {/* Right Section */}
        <div className='flex-1 lg:flex-[0.75] flex-center'>
          <iframe
            title='demo'
            src='https://drive.google.com/file/d/1sRcnAXTzAiOoxQ7wkDOob2f5mbuqFIQO/preview'
            // width='540'
            // height='340'
            allow='autoplay'
            className='w-[320px] h-[210px] lg:w-[460px] lg:h-[290px]'
          ></iframe>
        </div>
      </div>
    </SectionContainer>
  )
}

export default OptimizingAnalysts
