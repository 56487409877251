// ** React Imports
import React from 'react'

// ** React Router Imports
import { useLocation } from 'react-router-dom'

// ** Component Imports
import Header from './Header'
import Footer from './Footer'

// ** Routing Imports
import { dashboardRoutes, authRoutes } from '../navigation/routes'

// ** Interface
interface layoutProps {
  children: React.ReactNode
}

function Layout({ children }: layoutProps) {
  // ** Hooks
  const location = useLocation()

  // ** Variables
  const isHeaderHidden = dashboardRoutes.concat(authRoutes).some(route => location.pathname.includes(route))
  const isFooterHidden = dashboardRoutes.concat(authRoutes).some(route => location.pathname.includes(route))

  return (
    <div className={`${!isHeaderHidden && 'pt-[95px]'} overflow-x-hidden overflow-y-hidden`}>
      {!isHeaderHidden && <Header />}
      <main>{children}</main>
      {!isFooterHidden && <Footer />}
    </div>
  )
}

export default Layout
