// ** Types Imports
import { themeColor } from '../../types/theme/themeColor'

/**
 * A function that returns the tailwind
 * class name for appropriate text color
 * @param themeColor Color of the background
 */
export const getTextColorClassName = (themeColor: themeColor) => {
  const textColor =
    themeColor === 'primary-blue'
      ? 'text-primary-blue'
      : themeColor === 'secondary-blue'
        ? 'text-secondary-blue'
        : themeColor === 'accent-orange'
          ? 'text-accent-orange'
          : themeColor === 'background-cream'
            ? 'text-background-cream'
            : themeColor === 'light-blue'
              ? 'text-light-blue'
              : themeColor === 'chizl-black' && 'text-chizl-black'

  return textColor
}
