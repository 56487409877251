// ** React Imports
import React from 'react'

// ** Icons Imports
import HomeIcon from '@icons/HomeIcon'
import FileIcon from '@icons/FileIcon'
import ClockIcon from '@icons/ClockIcon'
import UsersGroupIcon from '@icons/UsersGroupIcon'

// ** Component Imports
import Sidebar from '@coreComponents/sidebar-components/Sidebar'
import SidebarGroup from '@coreComponents/sidebar-components/SidebarGroup'
import SidebarButton from '@coreComponents/sidebar-components/SidebarButton'

function ResearchReportsSidebar() {
  const sidebarNavigation = [
    { label: 'Home', icon: <HomeIcon /> },
    { label: 'My Reports', icon: <FileIcon /> },
    { label: 'Recent', icon: <ClockIcon /> },
    { label: 'Shared with me', icon: <UsersGroupIcon /> }
  ]

  return (
    <Sidebar>
      <SidebarGroup>
        {sidebarNavigation.map(button => (
          <SidebarButton color='primary-blue' textColor='primary-blue' icon={button.icon} className='bg-opacity-70'>
            {button.label}
          </SidebarButton>
        ))}
      </SidebarGroup>
    </Sidebar>
  )
}

export default ResearchReportsSidebar
