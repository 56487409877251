// ** React Imports
import React, { useState } from 'react'

// ** Icons Imports
import ArrowDownIcon from '@icons/ArrowDownIcon'
import ClickAwayListener from '@coreComponents/ClickAwayListener'

// ** Icons
import { IoLogOut } from 'react-icons/io5'
import { useAuth } from 'core/auth'

function UserDropdown() {
  const { logout } = useAuth()

  const [open, setOpen] = useState(false)

  const handleClickAway = () => {
    if (open) {
      setOpen(false)
    }
  }

  const handleClick = () => {
    setOpen(!open)
  }

  const handleLogOut = () => {
    logout()
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className='relative'>
        <button
          className='flex-center gap-2 border-secondary-blue border-[1px] w-[72px] rounded-full p-[2.5px] bg-chizl-black bg-opacity-50 pl-[7px]'
          onClick={handleClick}
        >
          <ArrowDownIcon color='white' />
          <img
            alt='user-profile'
            className='rounded-full w-[37px] h-[37px] object p-[1px]'
            style={{ objectFit: 'contain' }}
            src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
          />
        </button>

        {open && (
          <div className='absolute right-0 mt-2 w-[150px] bg-white border border-gray-300 rounded-lg shadow-lg z-50'>
            <button
              className='w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 rounded-lg'
              onClick={handleLogOut}
            >
              <div className='flex items-center gap-2'>
                <IoLogOut />
                Log Out
              </div>
            </button>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default UserDropdown
