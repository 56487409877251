// ** React Imports
import React, { SVGProps } from 'react'

const ArrowDownIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.998 5.32602L8 10.198L3.002 5.32602C2.9127 5.2388 2.79283 5.18996 2.668 5.18996C2.54317 5.18996 2.4233 5.2388 2.334 5.32602C2.29076 5.36838 2.25641 5.41895 2.23296 5.47475C2.20951 5.53056 2.19743 5.59048 2.19743 5.65102C2.19743 5.71155 2.20951 5.77147 2.23296 5.82728C2.25641 5.88309 2.29076 5.93365 2.334 5.97602L7.651 11.16C7.74437 11.251 7.86961 11.302 8 11.302C8.13039 11.302 8.25563 11.251 8.349 11.16L13.666 5.97702C13.7095 5.93462 13.7442 5.88393 13.7678 5.82794C13.7914 5.77195 13.8036 5.71179 13.8036 5.65102C13.8036 5.59024 13.7914 5.53009 13.7678 5.47409C13.7442 5.4181 13.7095 5.36741 13.666 5.32502C13.5767 5.2378 13.4568 5.18896 13.332 5.18896C13.2072 5.18896 13.0873 5.2378 12.998 5.32502V5.32602Z'
        fill={props.color}
      />
    </svg>
  )
}

export default ArrowDownIcon
