import SidebarPageWrapper from '@coreComponents/sidebar-components/SidebarPageWrapper'
import React from 'react'
import PublicFilingsSidebar from '../PublicFilingsSidebar'
import { dashboardConfig } from 'pages/DashboadLayout/dashboardConfig'
import { useAppSelector } from '@redux/store'
import USPublicFilingsHeader from '../USPublicFilingsHeader'
import PublicFilingsTable from '@components/public-filings-components/PublicFilingsTable'

function USFilings() {
  const publicFilingsStore = useAppSelector(state => state.publicFilings)

  return (
    <SidebarPageWrapper
      renderSidebar={<PublicFilingsSidebar />}
      className={`w-full gap-[35px] overflow-y-auto ${dashboardConfig.paddingClassName}`}
    >
      {/* Header */}
      <USPublicFilingsHeader />
      <div className='grid grid-cols-1 xl:grid-cols-2 gap-[35px]'>
        {publicFilingsStore.publicFilings !== undefined &&
          Object.keys(publicFilingsStore.publicFilings).map(key => (
            <PublicFilingsTable
              title={key}
              rows={publicFilingsStore.publicFilings && publicFilingsStore.publicFilings[key]}
            />
          ))}
      </div>
    </SidebarPageWrapper>
  )
}

export default USFilings
