// ** React Imports
import React from 'react'

// ** Assets Imports
import chizlIcon from '../../assets/chizl/chizl-icon.png'
import chizlTextIcon from '../../assets/chizl/chizl+text.png'
import whitespaceIcon from '../../assets/chizl/whitespace.png'
import chizlTextCream from '../../assets/chizl/chizl-text-white.png'
import chizlTextIconCream from '../../assets/chizl/chizl+text-cream.png'
import chizlOutlineText from '../../assets/chizl/chzil-outline+text.png'
import chizlOutlineWhite from '../../assets/chizl/chizl-outline-white.png'
import chizlOutlineOrange from '../../assets/chizl/chizl-outline-orange.png'

// ** Props Imports
import { chizlIconProps } from './chizlIconProps'

function ChizlIcon({ icon = 'chizl+text', size = '180px', ...rest }: chizlIconProps) {
  const srcIcon =
    icon === 'chizl+text'
      ? chizlTextIcon
      : icon === 'chizlTextCream'
        ? chizlTextCream
        : icon === 'chizlIcon'
          ? chizlIcon
          : icon === 'chizl+textCream'
            ? chizlTextIconCream
            : chizlIcon === 'whitespace'
              ? whitespaceIcon
              : icon === 'chizl-outline+text'
                ? chizlOutlineText
                : icon === 'chizl-outline-white'
                  ? chizlOutlineWhite
                  : icon === 'chizl-outline-orange' && chizlOutlineOrange

  return (
    <img
      alt='chizl'
      src={srcIcon}
      {...rest}
      className={`objct-contain ${rest.className}`}
      style={{
        width: typeof size === 'number' ? size + 'px' : typeof size === 'string' ? size : undefined
      }}
    />
  )
}

export default ChizlIcon
