// ** Core Component Imports
import Beta from '@coreComponents/Beta'
import ChizlIcon from '@coreComponents/ChizlIcon'

function ChatBotHeader() {
  return (
    <div className='flex items-center justify-between px-3 h-[45px]'>
      <ChizlIcon icon='chizlTextCream' size='60px' />

      <Beta />
    </div>
  )
}

export default ChatBotHeader
