// ** Icons Imports
import SECFileIcon from '@icons/SECFileIcon'
import DatabaseIcon from '@icons/DatabaseIcon'
import EqualizerIcon from '@icons/EqualizerIcon'
import ResearchReportsIcon from '@icons/ResearchReportsIcon'

// ** Assets
import vdr from './assets/vdr.png'
import transcription from './assets/transcription.png'
import publicFilings from './assets/public-filings.png'
import researchReports from './assets/research-reports.png'

// ** DATA
export const useCases = [
  {
    id: 'publicFilings',
    title: 'Public Filings',
    description:
      'Chat with a GPT-style model to extract key information and summarize critical sections of public filings​',
    iconBgColor: 'var(--secondary-blue)',
    icon: <SECFileIcon />,
    thumbnail: publicFilings
  },
  {
    id: 'researchReports',
    title: 'Research Reports',
    description:
      'Upload multiple equity reports, industry, and/or market reports into a drop box and chat with our AI model to extract key information ​',
    iconBgColor: 'var(--secondary-blue)',
    icon: <ResearchReportsIcon />,
    thumbnail: researchReports
  },
  {
    id: 'vdr',
    title: 'VDR Scraping',
    description: 'Interact with our AI model to extract key information from files in virtual data rooms (VDRs) ​',
    iconBgColor: 'var(--accent-orange)',
    icon: <DatabaseIcon />,
    thumbnail: vdr
  },
  {
    id: 'transcription',
    title: 'Transcribing Calls',
    description: 'Use our AI meeting assistant to transcribe meetings, extract key takeaways, and summarize content​',
    iconBgColor: 'var(--accent-orange)',
    icon: <EqualizerIcon />,
    thumbnail: transcription
  }
]
