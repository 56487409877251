// ** React Imports
import React, { useRef, useState } from 'react'

// ** Props Imports
import { chatBotInputProps } from './chatBotInputProps'

// ** Icons Imports
import { GoFile } from 'react-icons/go'
import { IoMdPause, IoMdSend } from 'react-icons/io'
import { AiOutlineClose } from 'react-icons/ai'
import { useAppDispatch, useAppSelector } from '@redux/store'
import { useLocation } from 'react-router-dom'

// ** Redux Actions
import { removeChatBotSelectedDocument } from '@redux/chatBot/chatBotSlice'

function ChatBotInput({ onMessage = () => {}, ...rest }: chatBotInputProps) {
  const location = useLocation()
  const dispatch = useAppDispatch()

  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [textMessage, setTextMessage] = useState<string>('')
  const [isTyping, setIsTyping] = useState<boolean>(false) // New state

  const chatBotStore = useAppSelector(state => state.chatBot)

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextMessage(e.target.value)
    adjustTextareaHeight()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      if (textMessage.trim()) {
        onMessage(textMessage)
        setTextMessage('')
        adjustTextareaHeight()
      }
    }
  }

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = '28.5px'
      textarea.style.height = textarea.scrollHeight + 'px'
    }
  }

  const onSend = () => {
    if (textMessage.trim()) {
      onMessage(textMessage)
      setTextMessage('')
      adjustTextareaHeight()
    }
  }

  const handleRemoveDocument = (id: string | number) => {
    dispatch(removeChatBotSelectedDocument(id))
  }

  return (
    <div className='p-3'>
      <div className='mb-xs'>
        {location.pathname === '/dashboard/multi_reports' &&
          chatBotStore.selectedDocuments?.map(document => (
            <span
              key={document.id}
              className='flex items-center gap-[3px] rounded-md bg-primary-blue font-semibold text-primary-blue bg-opacity-30 p-[2px] max-w-full w-fit'
            >
              <GoFile className='text-primary-blue' />
              <span className='text-sm text-ellipsis whitespace-nowrap truncate'>{document.name}</span>
              <button
                onClick={() => handleRemoveDocument(document.id)}
                className='mx-[3px] text-primary-blue rounded-full p-[2px] flex-center'
              >
                <AiOutlineClose size={13} />
              </button>
            </span>
          ))}
      </div>
      <div className='w-full flex items-start justify-center gap-3 bg-[#282828] rounded-[30px] min-h-[40px] px-[10px] py-[6px]'>
        <textarea
          ref={textareaRef}
          value={textMessage}
          placeholder='Ask Chizl here'
          onChange={handleTextareaChange}
          onKeyDown={handleKeyDown}
          className='w-full bg-transparent focus:outline-none rounded-2xl text-sm px-3 py-1 h-[28.5px] max-h-[90px] resize-none'
        />

        <button className='rounded-full p-1 flex-center mt-1' onClick={onSend}>
          {isTyping ? <IoMdPause className='text-light-blue' /> : <IoMdSend className='text-light-blue' />}
        </button>
      </div>
    </div>
  )
}

export default ChatBotInput
