// ** React Imports
import React from 'react'

// ** React Imports
import { Link } from 'react-router-dom'

// ** Assets Imports
import ChizlIcon from '@coreComponents/ChizlIcon'

// ** Component Imports
import UserDropdown from '@components/UserDropdown'
import DashboardNavigationHeader from '../DashboardNavigationHeader'
import Beta from '@coreComponents/Beta'

function DashboardHeader() {
  return (
    <div className='pb-[22px] flex items-center justify-between'>
      {/* Left Side */}
      <Link to='/dashboard/public_filings/regions' className='relative'>
        <ChizlIcon icon='chizl-outline+text' />
        <Beta className='absolute bottom-[-12px] right-[-5px]' />
      </Link>

      {/* Right Side */}
      <div className='flex flex-row items-center gap-[30px]'>
        <DashboardNavigationHeader />
        <UserDropdown />
      </div>
    </div>
  )
}

export default DashboardHeader
