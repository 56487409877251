// ** Props Imports
import { publicFilingsTableProps } from './publicFilingsTableProps'

// ** Component Imports
import PublicFilingsTableRow from '../PublicFilingsTableRow'

function PublicFilingsTable({ title, rows, ...rest }: publicFilingsTableProps) {
  return (
    <div className={`${rest.className} flex flex-col h-[300px] w-full`}>
      {title && <h2 className='font-bold mb-[10px]'>{title}</h2>}
      <div className='overflow-y-auto h-full border-[#5B5B5B] border-[1.5px] rounded-[4px] bg-[#363636] overflow-x-hidden'>
        <table className='border-collapse w-full table-fixed'>
          {rows?.map(row => <PublicFilingsTableRow row={row} />)}
        </table>
      </div>
    </div>
  )
}

export default PublicFilingsTable
