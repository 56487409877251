import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/store'
import { setLastChatBotMessage } from '@redux/chatBot/chatBotSlice'
import EmptyState from './components/EmptyState'
import ChatBotInput from './components/ChatBotInput'
import ChatBotHeader from './components/ChatBotHeader'
import ChatBotMessageBubble from './components/ChatBotMessageBubble'
import { ChatBotMessageInterface, _getChatBotHistory, _sendChatBotMessage } from '@api/chatbot'
import { useLocation } from 'react-router-dom'
import IconButton from '@coreComponents/IconButton'
import { BsArrowDown } from 'react-icons/bs'

function ChatBot() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const offering = location.pathname.startsWith('/dashboard/public_filings') ? 1 : 2
  const chatBotStore = useAppSelector(state => state.chatBot)
  const chatContainerRef = useRef<HTMLDivElement>(null)

  // Local loading state
  const [loading, setLoading] = useState(false)

  // Scroll to bottom state and handler
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false)
  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return

    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current
    const isScrolledToBottom = scrollHeight - scrollTop <= clientHeight + 10 // Adjust threshold as needed

    setIsScrollButtonVisible(!isScrolledToBottom)
  }, [])

  const scrollToBottom = () => {
    setTimeout(() => {
      const container = chatContainerRef.current
      if (container) {
        container.scrollTop = container.scrollHeight
      }
    }, 200)
  }

  const fetchChatHistory = useCallback(() => {
    _getChatBotHistory({ offering }, () => scrollToBottom())
  }, [offering])

  const sendMessage = (query: string) => {
    setLoading(true) // Set loading state to true
    dispatch(
      setLastChatBotMessage({
        user_id: 1,
        document_id: 1,
        query,
        session_id: 0,
        offering,
        sender: 'user'
      })
    )
    _sendChatBotMessage(
      {
        document_id: chatBotStore.selectedDocuments && chatBotStore.selectedDocuments[0].id,
        query,
        offering,
        language: chatBotStore.language,
        is_private: chatBotStore.is_private ?? false,
        filing: chatBotStore.filing
      },
      data => {
        dispatch(
          setLastChatBotMessage({
            user_id: 1,
            document_id: chatBotStore.selectedDocuments && chatBotStore.selectedDocuments[0].id,
            query: data.response,
            session_id: 1,
            offering,
            sender: 'bot',
            typed: true,
            id: data.id,
            plot_paths_to_s3: data.path_to_s3,
            highlighted_text: data.highlighted_text
          })
        )
        setLoading(false) // Set loading state to false
        scrollToBottom()
      },
      err => {
        setLoading(false)
      }
    )

    scrollToBottom()
  }

  useEffect(() => {
    fetchChatHistory()

    const container = chatContainerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [fetchChatHistory, handleScroll])

  // State for dot animation
  const [dotIndex, setDotIndex] = useState(0)
  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setDotIndex(prevIndex => (prevIndex + 1) % 3)
      }, 500)
      return () => clearInterval(intervalId)
    }
  }, [loading])

  // Dot animation styles
  const dotStyle = (index: number) => ({
    display: 'inline-block',
    width: '8px',
    height: '8px',
    margin: '0 2px',
    borderRadius: '50%',
    backgroundColor: 'white',
    opacity: dotIndex === index ? 1 : 0.5,
    transition: 'opacity 0.5s'
  })

  return (
    <div className='flex flex-col justify-between h-[100%] bg-[#1A1B1C]'>
      <ChatBotHeader />
      <div ref={chatContainerRef} className='flex-1 overflow-y-scroll p-[10px] scroll-smooth'>
        <div className='max-w-[650px] mx-auto overflow-y-hidden'>
          {chatBotStore.messages?.length ? (
            chatBotStore.messages.map((message: ChatBotMessageInterface) => (
              <ChatBotMessageBubble key={message.id} message={message} />
            ))
          ) : (
            <EmptyState />
          )}
          {loading && (
            <div className='my-[20px] flex justify-center'>
              <div style={dotStyle(0)}></div>
              <div style={dotStyle(1)}></div>
              <div style={dotStyle(2)}></div>
            </div>
          )}
        </div>
      </div>

      <div className='w-full max-w-[660px] mx-auto relative'>
        {isScrollButtonVisible && (
          <IconButton
            onClick={scrollToBottom}
            className='absolute bottom-5 left-1/2 transform -translate-x-1/2 top-[-30px] rounded-full bg-secondary-blue h-fit'
            style={{ boxShadow: '0 3px 4px 0 #00000058' }}
          >
            <BsArrowDown color='white' size={13} strokeWidth={1} />
          </IconButton>
        )}

        <ChatBotInput onMessage={sendMessage} />
      </div>
    </div>
  )
}

export default ChatBot
