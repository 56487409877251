// ** React Imports
import React from 'react'

// ** Props Imports
import { sidebarLabelProps } from './sidebarLabelProps'

function SidebarLabel({ ...rest }: sidebarLabelProps) {
  return <span className='text-background-cream text-opacity-70 text-[14px] mb-[5px]'>{rest.children}</span>
}

export default SidebarLabel
