import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'react-router-dom'
import Input from '@coreComponents/Input'
import Button from '@coreComponents/Button'
import ChizlIcon from '@coreComponents/ChizlIcon'
import IconButton from '@coreComponents/IconButton'
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5'
import Beta from '@coreComponents/Beta'
import blueStripesBg from '@assets/general/background-stripes-blue.png'
import { useAuth } from 'core/auth'

interface FormInputs {
  email: string
  password: string
}

const signInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string().required('Password is required')
})

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const { login, loginLoading } = useAuth()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormInputs>({
    resolver: yupResolver(signInSchema)
  })

  const onSubmit = async (data: FormInputs) => {
    try {
      await login(data.email, data.password)
    } catch (error) {
      console.error('Login failed:', error)
    }
  }

  return (
    <div className='relative overflow-x-hidden flex-center h-[100vh]'>
      <div className='flex flex-col gap-8 bg-chizl-black bg-opacity-20 border-2 border-primary-blue rounded-[40px] sm:px-[50px] px-[30px] py-[30px] w-[90%] max-w-[470px] z-10 backdrop-blur-sm'>
        <div className='flex items-center justify-between'>
          <Link to='/'>
            <ChizlIcon icon='chizl-outline+text' />
          </Link>
          <Beta />
        </div>
        <h1 className='font-semibold text-3xl'>Login</h1>

        <div className='w-full'>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <>
                <Input placeholder='Work Email' boxClassName='w-full' {...field} />
                {errors.email && <span className='text-red-500 text-sm'>{errors.email.message}</span>}
              </>
            )}
          />
        </div>

        <div className='w-full'>
          <Controller
            name='password'
            control={control}
            render={({ field }) => (
              <>
                <Input
                  placeholder='Password'
                  type={showPassword ? 'text' : 'password'}
                  EndIcon={
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                    </IconButton>
                  }
                  boxClassName='w-full'
                  {...field}
                />
                {errors.password && <span className='text-red-500 text-sm'>{errors.password.message}</span>}
              </>
            )}
          />
        </div>

        <Link to='#' className='hover:underline text-sm'>
          Forgot password?
        </Link>
        <Button
          color='secondary-blue'
          className='w-full font-semibold text-xl'
          onClick={handleSubmit(onSubmit)}
          loading={loginLoading}
        >
          Sign In
        </Button>
        <span className='text-sm text-center'>
          Don't have an account?{' '}
          <Link to='/register' className='hover:underline text-sm font-semibold'>
            Sign Up
          </Link>
        </span>
      </div>

      {/* Gradient */}
      <div
        style={{
          background: 'radial-gradient(circle, #005EA440 0%, transparent 80%)',
          height: '100vh',
          width: '100vw',
          zIndex: 1
        }}
        className='bg-fixed absolute top-0 bottom-0 left-0 right-0'
      />

      {/* Icons */}
      <ChizlIcon icon='chizlIcon' size={260} className='absolute left-2 bottom-10 -rotate-12 blur-[10px]' />
      <ChizlIcon icon='chizlIcon' size={140} className='absolute right-3 top-[50%] rotate-12 blur-[10px]' />

      {/* Lines */}
      <img alt='blue-stripes' src={blueStripesBg} className='rotate-180 fixed bottom-[-200px] w-full' />
      <img alt='blue-stripes' src={blueStripesBg} className='fixed top-[-200px] w-full' />
    </div>
  )
}

export default Login
