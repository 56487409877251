import { Company, _getPublicFilingCompanies, _getPublicFilingsByCompanyId } from '@api/public_filings'
import Autocomplete from '@coreComponents/Autocomplete'
import { optionInterface } from '@coreComponents/Autocomplete/autocompleteProps'
import SearchIcon from '@icons/SearchIcon'
import { setCompany } from '@redux/publicFilings/publicFilingsSlice'
import { useAppDispatch, useAppSelector } from '@redux/store'
import debounce from '@utils/debounce'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function USPublicFilingsHeader() {
  // ** Hooks
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  // ** States
  const [companyOptions, setCompanyOptions] = useState<Company[]>([])
  const publicFilingsStore = useAppSelector(state => state.publicFilings)

  // ** Handlers
  const onInputSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Clear Autocomplete options on input change
    setCompanyOptions([])

    // If input is empty -> clear options
    if (!e.target.value) {
      setCompanyOptions([])
      return
    }

    _getPublicFilingCompanies(
      e.target.value,
      (companies: Company[]) => {
        setCompanyOptions(companies)
      },
      error => console.error(`Error while fetchin public filing companies: ${error}`)
    )
  }

  // Function that executes on company autocomplete change
  const handleCompanySelect = (selectedOption: optionInterface) => {
    const selectedCompany: Company = selectedOption.value
    // Set the selected company in publicFilingsStore
    dispatch(setCompany(selectedCompany))
    navigate('/dashboard/public_filings/us')

    // Then fetch the public filings of that company set in the store
    _getPublicFilingsByCompanyId(selectedCompany.id)
  }

  return (
    <div className='flex items-center h-fit justify-between w-full mb-[20px] gap-[18px]'>
      {/* <Input
          boxStyle={{ borderRadius: '7px' }}
          EndIcon={<SearchIcon width={20} />}
          boxClassName='border-none bg-chizl-black w-[400px]'
          className='placeholder:text-[#B5B9BE]'
          placeholder='Search company here ex: APPL, NVDA '
        /> */}
      <Autocomplete
        containerStyle={{ flex: 1, maxWidth: '285px' }}
        onInputChange={debounce(onInputSearchChange, 500)}
        options={companyOptions.map(option => {
          return { label: option.display, value: option }
        })}
        inputProps={{
          placeholder: 'Search company here ex: APPL, NVDA ',
          StartIcon: SearchIcon,
          boxClassName: 'border-none bg-chizl-black'
        }}
        onChange={handleCompanySelect}
      />

      {publicFilingsStore.company?.display && (
        <div className='whitespace-nowrap border-[1.7px] rounded-[5px] px-md py-xs w-fit text-sm font-semibold border-light-blue h-fit bg-light-blue bg-opacity-20 text-light-blue'>
          {publicFilingsStore.company?.display}
        </div>
      )}
    </div>
  )
}

export default USPublicFilingsHeader
