// ** React Imports
import React from 'react'

// ** Core Component Imports
import ChizlIcon from '@coreComponents/ChizlIcon'

function EmptyState() {
  return (
    <div className='flex flex-col items-center justify-center h-[100%]'>
      <div className='rounded-full w-[41px] h-[41px] flex-center'>
        <ChizlIcon icon='chizl-outline-white' className='w-[30px]' />
      </div>
      <span className='text-white text-sm'>No chat, ask chizl to help you!</span>
    </div>
  )
}

export default EmptyState
