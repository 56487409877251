// ** Icon Imports
import RightArrowIcon from '../../../../core/icons/RightArrowIcon'

// ** Component Imports
import Button from '@coreComponents/Button'
import SectionContainer from '@coreComponents/SectionContainer'

function GetMoreDone({ id = '' }) {
  return (
    <SectionContainer id={id} className='flex lg:flex-row flex-col items-center py-[50px] gap-20'>
      <div className='flex-1 flex items-center justify-center flex-col'>
        <h1 className='text-5xl font-bold'>Get More Done with Chizl</h1>
        <div className='font-semibold text-2xl mb-[50px] mt-[30px]'>A one-stop-shop for all analysts’ needs</div>
        <Button color='secondary-blue' size='large' endIcon={<RightArrowIcon color='white' />}>
          Explore Chizl
        </Button>
      </div>
    </SectionContainer>
  )
}

export default GetMoreDone
