// ** React Imports
import { useState } from 'react'

// ** Assets Imports
import vdr from './assets/vdr.png'
import transcription from './assets/transcription.png'
import publicFilings from './assets/public-filings.png'
import researchReports from './assets/research-reports.png'

// ** Data
import { useCases } from './data'

// ** Component Imports
import UseCaseBox from './components/UseCaseBox'
import UseCasesMobile from './components/UseCasesMobile'
import SectionContainer from '@coreComponents/SectionContainer'

function UseCases({ id = '' }) {
  // ** States
  const [caseInView, setCaseInView] = useState<'vdr' | 'transcription' | 'researchReports' | 'publicFilings'>(
    'publicFilings'
  )

  // ** Handlers
  const handleUseCaseMouseOver = (id: string) => {
    if (id === 'publicFilings') setCaseInView('publicFilings')
    if (id === 'researchReports') setCaseInView('researchReports')
    if (id === 'transcription') setCaseInView('transcription')
    if (id === 'vdr') setCaseInView('vdr')
  }

  return (
    <SectionContainer id={id} className='mt-28 max-w-[1200px]'>
      <h1 className='text-5xl font-bold text-center'>Use Cases</h1>
      <div className='hidden lg:flex  lg:flex-row flex-col items-center py-[50px] justify-between gap-[20px]'>
        {/* Left Section */}
        <div
          className='max-h-[340px] w-[440px] lg:w-[450px] rounded-[26px]'
          style={{ boxShadow: '0 0 30px 0px var(--chizl-black)' }}
        >
          <img
            src={
              caseInView === 'publicFilings'
                ? publicFilings
                : caseInView === 'researchReports'
                  ? researchReports
                  : caseInView === 'transcription'
                    ? transcription
                    : caseInView === 'vdr' && vdr
            }
            alt='get-more-done'
            className='w-full h-full object-cover rounded-[26px]'
          />
        </div>

        {/* Right Section */}
        <div className='grid grid-cols-2 gap-3'>
          {useCases.map(({ id, title, description, iconBgColor, icon }) => (
            <UseCaseBox
              title={title}
              description={description}
              iconBgColor={iconBgColor}
              icon={icon}
              onMouseOver={() => handleUseCaseMouseOver(id)}
            />
          ))}
        </div>
      </div>

      <UseCasesMobile className='lg:hidden' />
    </SectionContainer>
  )
}

export default UseCases
