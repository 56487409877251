/**
 * A util function that will smoothly scroll to the position of the container with the targeted id
 * @param id The Id of the container to be be scrolled to
 */
export const smoothScrollTo = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    const scrollTop = element.getBoundingClientRect().top + window.pageYOffset - 50
    window.scrollTo({
      top: scrollTop,
      behavior: 'smooth'
    })
  }
}
