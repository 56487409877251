import toast, { ToastOptions } from 'react-hot-toast'

// Define the types for the toast types you want to handle
type ToastTypeKey = 'success' | 'error'

const createCustomToast = (type: ToastTypeKey, message: string) => {
  const baseStyle = {
    border: '2px solid #005ea4',
    padding: '5px 10px',
    color: 'white',
    backgroundColor: '#222325'
  }

  const iconTheme = {
    primary: '#005ea4',
    secondary: 'white'
  }

  const commonOptions: ToastOptions = {
    position: 'top-right',
    style: {
      marginTop: '60px' // Lower the toast by 60px
    }
  }

  const typeSpecificStyles: Record<ToastTypeKey, ToastOptions> = {
    success: {
      ...commonOptions,
      style: {
        ...commonOptions.style,
        ...baseStyle,
        border: '2px solid #28a745' // Green border for success
      },
      iconTheme: {
        primary: '#28a745', // Green icon for success
        secondary: 'white'
      }
    },
    error: {
      ...commonOptions,
      style: {
        ...commonOptions.style,
        ...baseStyle,
        border: '2px solid #d32f2f' // Red border for error
      },
      iconTheme: {
        primary: '#d32f2f', // Red icon for error
        secondary: 'white'
      }
    }
  }

  return toast[type](message, typeSpecificStyles[type])
}

const customToast = {
  success: (message: string) => createCustomToast('success', message),
  error: (message: string) => createCustomToast('error', message)
  // Add more methods like 'info', 'warning' if needed
}

export { customToast as toast }
