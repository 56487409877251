// ** React Imports
import React from 'react'

// ** Props Imports
import { sidebarGroupProps } from './sidebarGroupProps'

function SidebarGroup({ color = 'primary-blue', ...rest }: sidebarGroupProps) {
  return (
    <div data-color={color} className='flex flex-col items-start mb-[24px]'>
      {rest.children}
    </div>
  )
}

export default SidebarGroup
