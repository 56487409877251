// ** React Imports
import React, { SVGProps } from 'react'

const HomeIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width='12' height='14' viewBox='0 0 12 14' fill='none' {...props}>
      <path d='M0 13.75V4.75L6 0.25L12 4.75V13.75H7.5V8.5H4.5V13.75H0Z' fill={props.color ?? 'currentColor'} />
    </svg>
  )
}

export default HomeIcon
