// ** Axios Imports
import { AxiosError } from 'axios'

// ** API Imports
import { API } from '..'

// ** Redux Imports
import { store } from '@redux/store'
import { setChatBotMessages } from '@redux/chatBot/chatBotSlice'

export interface ChatBotMessageInterface {
  created_at?: string
  document_id?: number | string
  id?: number
  offering?: number
  query: string
  sender: 'user' | 'bot'
  plot_paths_to_s3?: string[]
  session_id: number
  user_id: number
  typed?: boolean
  highlighted_text?: {
    page?: number
    text: string[]
  }[]
}

/**
 * Get the chatbot messages history
 * @param user_id The id of the user
 * @param offering The id of the offering
 * @param errCallback Function to handle error response
 */
export const _getChatBotHistory = (
  { offering = 1 }: { offering?: number },
  callback?: (companies: any) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.get(`/chatbot/history?offering=${offering}`)
    .then(res => {
      store.dispatch(setChatBotMessages(res.data as ChatBotMessageInterface[]))
      callback && callback(res.data as ChatBotMessageInterface[])
    })
    .catch(err => errCallback && errCallback(err))
}

/**
 * Send a message to the chat bot
 * @param session_id The id of the session
 * @param document_id The id of the document
 * @param user_id The id of the user
 * @param offering The id of the offering
 * @param query The message query
 * @param callback Function to handle successful response
 * @param errCallback Function to handle error response
 */
export const _sendChatBotMessage = (
  {
    session_id = 0,
    document_id,
    user_id = 0,
    offering = 0,
    query,
    is_private,
    language,
    filing
  }: {
    filing?: 'sec' | 'gcc'
    language?: 'Ar' | 'En'
    is_private?: boolean
    session_id?: number
    document_id?: number | string
    user_id?: number
    offering?: number
    query: string
  },
  callback?: (companies: any) => void,
  errCallback?: (error: AxiosError) => void
) => {
  const requestData = {
    session_id,
    document_ids: [document_id],
    is_private,
    language,
    user_id: 1,
    filing,
    offering,
    query,
    sender: 'user'
  }

  API.post(`/chatbot/`, requestData)
    .then(res => callback && callback(res.data as { response: string; path_to_s3?: string[] }))
    .catch(err => errCallback && errCallback(err))
}

export const _sendChatBotMessageFeedback = (
  {
    chat_message_id,
    thumbs_down = false,
    feedback_details = {}
  }: {
    chat_message_id: string | number
    thumbs_down: boolean
    feedback_details?: {
      feedback_type?: 'poor_answer' | 'wrong_document'
      poor_answer_type?: 'numerical' | 'logical' | 'response_format' | 'incomplete_answer' | 'other'
      // table?: 'private_document' | 'public_document'
      retrieved_pages?: number[]
      corrected_pages?: number[]
      comments?: string
    }
  },
  callback?: (companies: any) => void,
  errCallback?: (error: AxiosError) => void
) => {
  const requestData = {
    chat_message_id,
    thumbs_down,
    feedback_details
  }

  API.post(`/chatbot/feedback`, requestData)
    .then(res => callback && callback(res.data as any))
    .catch(err => errCallback && errCallback(err))
}
