import React, { useRef, useState } from 'react'
import UploadIcon from '@icons/UploadIcon'
import SearchIcon from '@icons/SearchIcon'
import Input from '@coreComponents/Input'
import Button from '@coreComponents/Button'
import Modal from '@coreComponents/Modal' // Ensure correct path to Modal
import { IoClose, IoInformationCircleOutline } from 'react-icons/io5'
import {
  _getPrivateDocuments,
  _getResearchReports,
  _uploadPrivateResearchReportDocument,
  _uploadResearchReportDocument
} from '@api/research_reports'
import { useAppDispatch } from '@redux/store'
import { setFetchLoading, setResearchReports, setSearchValue } from '@redux/researchReports/researchReportsSlice'
import { toast } from 'core/helpers/toast'

import Lottie from 'lottie-react'
import uploadAnimation from 'core/assets/lottie/uploading.json'
import { BsCheck, BsCheckLg, BsInfoCircle } from 'react-icons/bs'

type LanguageType = 'Ar' | 'En'

function Radio({ checked = false, onChange = () => {} }) {
  return (
    <div
      className='border-[1.6px] border-secondary-blue rounded-full w-[13px] h-[13px] p-[1px] flex-center'
      onClick={onChange}
    >
      {checked && <div className='w-full h-full bg-secondary-blue rounded-full' />}
    </div>
  )
}

function Checkbox({ checked = false, onChange = (e: any) => {} }) {
  return (
    <div
      className='border-[1.6px] border-secondary-blue rounded-[3px] w-[15px] h-[14px] flex-center'
      onClick={onChange}
    >
      {checked && <BsCheckLg className='text-white' size={12} strokeWidth={0.4} />}
    </div>
  )
}

function ResearchReportsHeader() {
  const dispatch = useAppDispatch()

  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [language, setLanguage] = useState<LanguageType>('En')
  const [isPrivate, setIsPrivate] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)

  const handleUploadClick = () => {
    setIsModalOpen(true)
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    setSelectedFile(file)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedFile(undefined) // Clear selected file when closing modal
  }

  const fetchDocuments = () => {
    dispatch(setFetchLoading(true))
    _getResearchReports(
      documents => {
        _getPrivateDocuments(
          privateDocs => {
            const updatedPrivateDocs = privateDocs.map(doc => ({
              ...doc,
              isPrivate: true
            }))
            dispatch(setResearchReports([...documents, ...updatedPrivateDocs]))
            dispatch(setFetchLoading(false))
          },
          err => {
            dispatch(setFetchLoading(false))
          }
        )
      },
      err => {
        dispatch(setFetchLoading(false))
      }
    )
  }

  const handleConfirmUpload = () => {
    if (selectedFile) {
      const uploadFunction = isPrivate ? _uploadPrivateResearchReportDocument : _uploadResearchReportDocument
      setUploading(true)
      uploadFunction(
        { file: selectedFile, language },
        data => {
          console.log('Upload success:', data)
          setIsModalOpen(false)
          toast.success('Document uploaded')
          setSelectedFile(undefined) // Clear selected file after upload
          fetchDocuments()
          setUploading(false)
        },
        error => {
          setUploading(false)
          toast.error('Could not upload document')

          console.error('Upload error:', error)
        }
      )
    } else {
      console.error('No file selected.')
    }
  }

  return (
    <div className='flex items-center h-fit gap-[10px] mb-[35px]'>
      <Input
        placeholder='Search documents'
        StartIcon={SearchIcon}
        boxClassName='border-none bg-chizl-black w-full max-w-[380px]'
        boxStyle={{ borderRadius: '5px' }}
        EndIcon={<span className='text-xs whitespace-nowrap'>(PDF, XML, pptx, doc, docx)</span>}
        onChange={e => {
          dispatch(setSearchValue(e.target.value))
        }}
      />

      <input ref={fileInputRef} accept='.pdf' type='file' className='hidden ml-auto' onChange={handleFileChange} />

      <Button
        endIcon={<UploadIcon />}
        className='whitespace-nowrap ml-auto h-[40px] text-black text-sm'
        onClick={handleUploadClick}
      >
        Upload Document
      </Button>

      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <div className='p-4 h-[395px]'>
            {uploading ? (
              <div>
                <h1 className='font-semibold text-xl'>Uploading and processing...</h1>
                <Lottie animationData={uploadAnimation} className='h-[350px] mt-[-30px] mb-[-30px]' />
                <div className='flex flex-row items-center gap-3'>
                  <BsInfoCircle size={15} className='text-gray-400' />
                  <span className='text-sm flex-1'>
                    Your file is being uploaded and processed, this may take up to a few minutes.
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className='flex items-center justify-between mb-[10px]'>
                  <h2 className='text-lg font-bold'>Upload Document</h2>
                  <button onClick={handleCloseModal}>
                    <IoClose color='white' />
                  </button>
                </div>
                {/* Language Selection */}
                <div className='mb-[10px]'>
                  <label className='block text-sm font-medium text-white'>Language:</label>
                  <div className='flex items-center space-x-4'>
                    <label className='flex items-center space-x-2'>
                      <input
                        type='radio'
                        value='En'
                        checked={language === 'En'}
                        onChange={() => setLanguage('En')}
                        className='hidden'
                      />
                      <Radio checked={language === 'En'} onChange={() => setLanguage('En')} />
                      <span className='text-white'>English</span>
                    </label>
                    <label className='flex items-center space-x-2'>
                      <input
                        type='radio'
                        value='Ar'
                        checked={language === 'Ar'}
                        onChange={() => setLanguage('Ar')}
                        className='hidden'
                      />
                      <Radio checked={language === 'Ar'} onChange={() => setLanguage('Ar')} />
                      <span className='text-white'>عربي</span>
                    </label>
                  </div>
                </div>
                {/* Privacy Selection */}
                <div className='mb-[10px]'>
                  <label className='block mb-2 text-sm font-medium text-white'>Document Type:</label>
                  <label className='flex items-center space-x-2'>
                    <input
                      type='checkbox'
                      checked={isPrivate}
                      onChange={e => setIsPrivate(e.target.checked)}
                      className='hidden'
                    />
                    <Checkbox checked={isPrivate} onChange={e => setIsPrivate(e.target.checked)} />
                    <span className='text-white'>Private Document</span>
                  </label>
                </div>
                <div className='flex items-center gap-[10px] my-[20px]'>
                  <IoInformationCircleOutline size={50} color='white' />
                  <span className='text-xs line-clamp-6'>
                    Documents and prompts that contain confidential information will not be used for training purposes.
                    The data is end-to-end encrypted to ensure your data is private and secured
                  </span>
                </div>

                {/* File Input */}
                <div className='mb-[10px]'>
                  <label className='block mb-2 text-sm font-medium text-white'>Select File:</label>
                  <input
                    ref={fileInputRef}
                    accept='.pdf'
                    type='file'
                    className='border-2 border-gray-400 rounded p-2 w-full'
                    onChange={handleFileChange}
                  />
                </div>

                {/* Upload Button */}
                <Button onClick={handleConfirmUpload} className='mx-auto'>
                  Upload
                </Button>
              </>
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}

export default ResearchReportsHeader
