// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// ** Interfaces Imports
import { ResearchReportsDocumentInterface } from '@api/research_reports'

export interface ResearchReportsState {
  fetchLoading: boolean
  researchReports?: ResearchReportsDocumentInterface[]
  selectedDocument?: ResearchReportsDocumentInterface
  searchValue?: string
}

const initialState: ResearchReportsState = {
  fetchLoading: false,
  researchReports: undefined,
  selectedDocument: undefined,
  searchValue: undefined
}

export const researchReportsSlice = createSlice({
  name: 'researchReports',
  initialState,
  reducers: {
    /**
     * Set the selected document
     */
    setSelectedResearchReport: (state, action: PayloadAction<ResearchReportsDocumentInterface>) => {
      state.selectedDocument = action.payload
    },

    /**
     * Clear the selected document
     */
    clearSelectedDocument: state => {
      state.selectedDocument = undefined
    },

    /**
     * Set the Research Report documents
     */
    setResearchReports: (state, action: PayloadAction<ResearchReportsDocumentInterface[]>) => {
      state.researchReports = action.payload
    },

    /**
     * Clear the public filings data
     */
    clearResearchReports: state => {
      state.researchReports = undefined
    },

    setFetchLoading: (state, action: PayloadAction<boolean>) => {
      state.fetchLoading = action.payload
    },

    /**
     * Clear all Public Filings related data
     */
    clearState: state => {
      state = initialState
    },

    setSearchValue: (state, action: PayloadAction<string | undefined>) => {
      state.searchValue = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setResearchReports,
  setSelectedResearchReport,
  clearResearchReports,
  clearSelectedDocument,
  clearState,
  setFetchLoading,
  setSearchValue
} = researchReportsSlice.actions

export default researchReportsSlice.reducer
