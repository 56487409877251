import toast from 'react-hot-toast'

function copyClipboard(text: string): void {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Text copied to clipboard')
        toast.success('Copied to clipboard')
      },
      err => {
        console.error('Failed to copy text: ', err)
      }
    )
  } else {
    // Fallback for older browsers
    const textarea = document.createElement('textarea')
    textarea.value = text
    document.body.appendChild(textarea)
    textarea.select()
    try {
      document.execCommand('copy')
      console.log('Text copied to clipboard')
      toast.success('Copied to clipboard')
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
    document.body.removeChild(textarea)
  }
}

export default copyClipboard
