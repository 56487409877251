// ** React Imports
import React from 'react'

// ** Assets Imports
import illustration from './assets/illustration.png'

// ** Icon Imports
import ChizlIcon from '@coreComponents/ChizlIcon'

// ** Component Imports
import SectionContainer from '@coreComponents/SectionContainer'

function WorkTogether({ id = '' }) {
  return (
    <SectionContainer
      id={id}
      className='flex lg:flex-row flex-col-reverse items-center mt-[200px] mb-[100px] gap-20 relative'
    >
      {/* Left Section */}
      <div className='flex-1 flex-center'>
        <img src={illustration} alt='optimizing-analysts' className='h-[350px] object-contain' />
      </div>

      {/* Right Section */}
      <div className='flex-1 flex items-center justify-center flex-col lg:block text-center lg:text-left'>
        <h1 className='text-5xl font-bold text-accent-orange'>Save Time</h1>
        <div className='text-2xl my-[65px]'>
          Our solution aims to save 25% of analysts' time spent on redundant tasks. Analysts will be able to redirect
          their focus towards strategic contributions, leading to an improved work-life balance. This allows banks to
          minimize employee turnover while maximizing analyst work efficiency.
        </div>

        <ChizlIcon icon='chizl-outline+text' className='ml-auto w-[100px]' />

        <ChizlIcon icon='chizlIcon' className='blur-sm absolute top-[-10px] right-[-90px] w-[300px] opacity-50' />
      </div>
    </SectionContainer>
  )
}

export default WorkTogether
