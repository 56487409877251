// ** Axios Imports
import axios from 'axios'

// ** Axios Parameters
const regularBaseURL = 'https://www.chizl-api.ai/'
const secIoBaseUrl = 'https://api.sec-api.io/'

// ** Axios Instances
export const API = axios.create({
  baseURL: regularBaseURL,
  timeout: 9000000 // 5 minutes in milliseconds
})

export const SecIoAPI = axios.create({
  baseURL: secIoBaseUrl,
  headers: {
    Authorization: '942dd43c54be469054c83dbba5c49205c7153344a9bb74921a2eee83b4410a4d'
  },
  timeout: 9000000 // 5 minutes in milliseconds
})

API.interceptors.request.use(
  async config => {
    const token = sessionStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
