/**
 * Convert ISO 8601 date-time format to the desired format (DD - MMM - YYYY hh:mm AM/PM)
 * @param isoDateTime ISO 8601 date-time format (e.g., "2024-05-07T17:17:37.124549")
 * @returns Formatted date-time string (e.g., "07 - May - 2024 05:17 PM")
 */
export function formatDateTime(isoDateTime: string, showTime: boolean = false): string {
  const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const dateObj: Date = new Date(isoDateTime)
  const day: string = dateObj.getDate().toString().padStart(2, '0')
  const month: string = months[dateObj.getMonth()]
  const year: string = dateObj.getFullYear().toString()
  // const hours: string = dateObj.getHours().toString().padStart(2, '0')
  const minutes: string = dateObj.getMinutes().toString().padStart(2, '0')
  const amOrPm: string = dateObj.getHours() >= 12 ? 'PM' : 'AM'
  const formattedHours: number = dateObj.getHours() % 12 || 12

  return `${day}-${month}-${year} ${showTime ? `${formattedHours}:${minutes} ${amOrPm}` : ''}`
}
