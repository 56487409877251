// ** React Imports
import React from 'react'

// ** React Router Dom Imports
import { Outlet, useLocation } from 'react-router-dom'

// ** Assets Imports
import blueStripesBg from './assets/background-stripes-blue.png'

// ** Core Component Imports
import SectionContainer from '@coreComponents/SectionContainer'

// ** React Resizable Imports
import { ResizableBox } from 'react-resizable'

// ** Component Imports
import ChatBot from '@components/ChatBot'
import DashboardHeader from './components/DashboardHeader'

const DashboardLayout = () => {
  const location = useLocation()

  // ** Handlers
  const handleResizeStart = () => {
    document.body.classList.add('no-select')
  }

  const handleResizeStop = () => {
    document.body.classList.remove('no-select')
  }

  return (
    <div className='relative dark:bg-[#101011]'>
      {/* Stripes Background */}
      <img alt='blue-stripes' src={blueStripesBg} className='fixed bottom-0 w-full' />

      {/* Main Container */}
      <SectionContainer className='z-10 h-[100vh] flex flex-col'>
        {/* Dashboard Header */}
        <DashboardHeader />

        <div className='flex relative flex-1 overflow-hidden rounded-[20px]'>
          <div className='flex flex-col flex-1'>
            {/* Page Container */}
            <div className='bg-[#151515] h-full overflow-auto'>
              <Outlet />
            </div>
          </div>
          {/* ChatBot */}
          {location.pathname !== '/dashboard/general' && location.pathname !== '/dashboard/public_filings/regions' && (
            <ResizableBox
              width={300}
              height={Infinity}
              resizeHandles={['w']}
              minConstraints={[300, Infinity]} // Minimum width
              maxConstraints={[500, Infinity]} // Maximum width
              onResizeStart={handleResizeStart}
              onResizeStop={handleResizeStop}
            >
              {/* <div style={{ width: `${chatbotWidth}px`, height: '100%' }}> */}
              <ChatBot />
              {/* </div> */}
            </ResizableBox>
          )}
        </div>
      </SectionContainer>
    </div>
  )
}

export default DashboardLayout
