import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@redux/store'
import ResearchReportFile from '@components/research-reports-components/ResearchReportFile'
import {
  addChatBotSelectedDocument,
  clearFiling,
  clearSelectedDocuments,
  setChatBotSelectedDocuments,
  setDocumentIds,
  setIsPrivate,
  setLanguage,
  setOffering
} from '@redux/chatBot/chatBotSlice'
import { ResearchReportsDocumentInterface } from '@api/research_reports'
import { ShimmerDiv } from 'shimmer-effects-react'

function ResearchReportsBody() {
  // Hooks
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  // States
  const loading = useAppSelector(state => state.researchReports.fetchLoading)
  const searchValue = useAppSelector(state => state.researchReports.searchValue) || ''
  const researchReports = useAppSelector(state => state.researchReports.researchReports)
  const chatBotSelectedDocuments = useAppSelector(state => state.chatBot.selectedDocuments)

  // Handlers
  const handleFileSingleClick = (document: ResearchReportsDocumentInterface) => {
    dispatch(clearSelectedDocuments())
    dispatch(
      addChatBotSelectedDocument({
        id: document.id ?? '',
        name: document.document_name,
        is_private: document.isPrivate ?? false
      })
    )
    dispatch(setDocumentIds([document.id ?? '']))
    dispatch(setIsPrivate(document.isPrivate ?? false))
    dispatch(setLanguage('En'))
    dispatch(clearFiling())
    dispatch(setOffering(2))
  }

  const handleFileDoubleClick = (documentId: string) => {
    navigate(`/dashboard/multi_reports/${documentId}`)
  }

  // Filter research reports by search value
  const filteredReports = researchReports?.filter(document =>
    document.document_name.toLowerCase().includes(searchValue.toLowerCase())
  )

  return (
    <div className='flex justify-start items-center gap-[30px] flex-wrap'>
      {loading
        ? new Array(6).fill(0).map(() => <ShimmerDiv mode='dark' border={0} height={197} width={225} rounded={0.75} />)
        : filteredReports?.map((document, index) => (
            <ResearchReportFile
              key={document.id}
              name={document.document_name}
              imgUrl={document.screenshot_s3_path}
              extension={document.extension}
              isPrivate={document.isPrivate}
              isSelected={chatBotSelectedDocuments?.find(el => el.id === document.id) ? true : false}
              onClick={() => handleFileSingleClick(document)}
              onDoubleClick={() => document.id && handleFileDoubleClick(document.id)}
              dateUploaded={document.uploaded_at}
            />
          ))}
    </div>
  )
}

export default ResearchReportsBody
