// ** Assets Imports
import illustration from './assets/illustration.png'

// ** Icon Imports
import ChizlIcon from '@coreComponents/ChizlIcon'

// ** Core Component Imports
import SectionContainer from '@coreComponents/SectionContainer'

function CustomizedNeeds({ id = '' }) {
  return (
    <SectionContainer id={id} className='flex lg:flex-row flex-col items-center py-[50px] gap-20'>
      <div
        className='flex bg-light-black lg:flex-row flex-col items-center py-[50px] gap-10 rounded-[60px] border-2 border-primary-blue p-xl'
        style={{ boxShadow: '0 0 30px 0px var(--primary-blue)' }}
      >
        {/* Left Section */}
        <div className='flex-1 flex items-center justify-center flex-col lg:block text-center lg:text-left'>
          <h1 className='text-5xl font-bold text-primary-blue'>Increase Efficiency</h1>
          <div className='text-xl mb-[50px] mt-[30px]'>
            Chizl currently uses Gemini's API as its LLM model to ensure that the information analysts extract are
            efficient, accurate, and concise.
          </div>
          <ChizlIcon sizes='20px' icon='chizl-outline+text' className='w-[100px]' />
        </div>

        {/* Right Section */}
        <div className='flex-1 flex-center'>
          <img src={illustration} alt='get-more-done' className='h-[340px] object-contain' />
        </div>
      </div>
    </SectionContainer>
  )
}

export default CustomizedNeeds
