// ** React Imports
import React, { useEffect } from 'react'

// ** Redux Imports
import { useAppDispatch, useAppSelector } from '@redux/store'
import { setOffering } from '@redux/chatBot/chatBotSlice'
import { setFetchLoading, setResearchReports } from '@redux/researchReports/researchReportsSlice'

// ** Config Imports
import { dashboardConfig } from '../../dashboardConfig'

// ** API Imports
import { _getPrivateDocuments, _getResearchReports } from '@api/research_reports'

// ** Component Imports
import ResearchReportsBody from './components/ResearchReportsBody'
import ResearchReportsHeader from './components/ResearchReportsHeader'
import ResearchReportsSidebar from './components/ResearchReportsSidebar'
import SidebarPageWrapper from '@coreComponents/sidebar-components/SidebarPageWrapper'

function ResearchReports() {
  // ** Hooks
  const dispatch = useAppDispatch()

  // ** States
  const documents = useAppSelector(state => state.researchReports.researchReports)

  useEffect(() => {
    dispatch(setOffering(2))
    if (!documents || (documents && documents.length === 0)) {
      dispatch(setFetchLoading(true))
      _getResearchReports(
        documents => {
          _getPrivateDocuments(
            privateDocs => {
              const updatedPrivateDocs = privateDocs.map(doc => ({
                ...doc,
                isPrivate: true
              }))
              dispatch(setFetchLoading(false))
              dispatch(setResearchReports([...documents, ...updatedPrivateDocs]))
            },
            err => {
              dispatch(setFetchLoading(false))
            }
          )
        },
        err => {
          dispatch(setFetchLoading(false))
        }
      )
    }
  }, [dispatch])

  return (
    <SidebarPageWrapper
      renderSidebar={<ResearchReportsSidebar />}
      className={`overflow-y-auto ${dashboardConfig.paddingClassName}`}
    >
      <ResearchReportsHeader />
      <ResearchReportsBody />
    </SidebarPageWrapper>
  )
}

export default ResearchReports
