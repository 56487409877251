// ** Props Imports
import { inputProps } from './inputProps'

// ** Component Imports
import Box from '../Box'

function Input({
  variant = 'bordered',
  boxColor = 'background-cream',
  StartIcon,
  EndIcon,
  label,
  boxClassName,
  boxStyle,
  ...rest
}: inputProps) {
  return (
    <div>
      {label && <span>{label}</span>}
      <Box
        style={boxStyle}
        color={boxColor}
        className={`flex items-center justify-start rounded-[6px] gap-[7px] py-[6px] px-[9px] border-[2.5px] bg-opacity-15 bg-background-cream-primary h-[39px] ${boxClassName}`}
      >
        {StartIcon}
        <input
          {...rest}
          className={`bg-transparent text-white focus:outline-none placeholder:text-background-cream w-full ${rest.className ?? ''}`}
        />
        {EndIcon}
      </Box>
    </div>
  )
}

// ** Display Name
Input.displayName = 'Input'

export default Input
